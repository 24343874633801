export const CHECK_ALMA_ELIGIBILITY = 'CHECK_ALMA_ELIGIBILITY';
export const CHECK_ALMA_ELIGIBILITY_SUCCEEDED = 'CHECK_ALMA_ELIGIBILITY_SUCCEEDED';
export const CREATE_ALMA_PAYMENT = 'CREATE_ALMA_PAYMENT';
export const CREATE_ALMA_PAYMENT_SUCCEEDED = 'CREATE_ALMA_PAYMENT_SUCCEEDED';
export const CREATE_ALMA_PAYMENT_FAILED = 'CREATE_ALMA_PAYMENT_FAILED';
export const GET_ALMA_PAYMENT_INFOS = 'GET_ALMA_PAYMENT_INFOS';
export const GET_ALMA_PAYMENT_INFOS_SUCCEEDED = 'GET_ALMA_PAYMENT_INFOS_SUCCEEDED';
export const GET_ALMA_PAYMENT_INFOS_FAILED = 'GET_ALMA_PAYMENT_INFOS_FAILED';
export const CHANGE_ALMA_PAYMENT_ID = 'CHANGE_ALMA_PAYMENT_ID';
export const SET_ALMA_ERROR = 'SET_ALMA_ERROR';

export const RESET_ALMA_ELIGIBILITY = 'RESET_ALMA_ELIGIBILITY';

export const checkAlmaEligibility = (payload) => ({
  type: CHECK_ALMA_ELIGIBILITY,
  payload
})
export const checkAlmaEligibilitySucceeded = (payload) => ({
  type: CHECK_ALMA_ELIGIBILITY_SUCCEEDED,
  payload
})
export const resetAlmaEligibility = () => ({
  type: RESET_ALMA_ELIGIBILITY
})
export const createAlmaPayment = (payload) => ({
  type: CREATE_ALMA_PAYMENT,
  payload
})
export const createAlmaPaymentSucceeded = (payload) => ({
  type: CREATE_ALMA_PAYMENT_SUCCEEDED,
  payload
})
export const getAlmaPaymentInfos = (payload) => ({
  type: GET_ALMA_PAYMENT_INFOS,
  payload
})
export const getAlmaPaymentInfosSucceeded = (payload) => ({
  type: GET_ALMA_PAYMENT_INFOS_SUCCEEDED,
  payload
})
export const getAlmaPaymentInfosFailed = (payload) => ({
  type: GET_ALMA_PAYMENT_INFOS_FAILED,
  payload
})
export const changeAlmaPaymentId = (payload) => ({
  type: CHANGE_ALMA_PAYMENT_ID,
  payload
})
export const createAlmaPaymentFailed = (payload) => ({
  type: CREATE_ALMA_PAYMENT_FAILED,
  payload
})
export const setAlmaError = (payload) => ({
  type: SET_ALMA_ERROR,
  payload
})
