import React from 'react'
import { FaStripe } from 'react-icons/fa';
import { GrSecure } from 'react-icons/gr';
import { RiSecurePaymentFill } from 'react-icons/ri';


const SecureIcons = () => {
    return(
        <div>
            <div>Mode de payement sécurisé et données protégées</div>
            <div>
                <FaStripe className="secure-icons"/>
                <GrSecure className="secure-icons"/>
                <RiSecurePaymentFill className="secure-icons"/>
            </div>
        </div>
    )
}

export default SecureIcons;