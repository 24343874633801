import './style.scss';
import './../style.scss';

import React, {useEffect, useState} from 'react';

import Seller from "../components/seller";
import {useForm} from "react-hook-form";
import CustomerIdentity from "../components/customerIdentity";
import CustomerAddress from "../components/customerAddress";

import CardInformation from "../payment/card/stripe/cardInformation";
import {orderBump} from "../../../store/offers/products/bumps";
import CGV from "../components/cgv";
import {RecapCommand} from "./recapCommand";
import {CardBump} from "./cardBump";
import {useDispatch, useSelector} from "react-redux";
import {saveLocallyCustomerInformation} from "../../../store/customer/customerActions";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {launchCardStripeProcessing} from "../../../store/payment/stripe/stripeActions";
import SuccessRedirection from "../payment/card/stripe/successRedirection";
import PaymentAlmaFacility from "../payment/card/alma/paymentAlmaFacility";
import {checkAlmaEligibility, createAlmaPayment, resetAlmaEligibility} from "../../../store/payment/alma/almaActions";

const CheckoutAutoForm = ({gender, product}) => {
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();
  const className = gender === "homme" ? "border-homme payment-form-large" : "border-femme payment-form-large";
  const orderedRequiredFieldsForCheckAlma = ["email", "lastname", "firstname", "phone", "address", "zip", "city", "country", "formule", "facility"];
  const {
    register, trigger, formState: {errors, isDirty}, handleSubmit, setFocus, clearErrors, control, getValues, setValue
  } = useForm();
  const productBump = orderBump(product.code, gender);
  const [bumpCheck, handleBumpCheck] = useState(false);
  const [total, setTotal] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(false);
  const {bankInformationFulfilled} = useSelector((state) => state.stripeState);
  const stripePaymentProcessing = useSelector((state) => state.stripeState.paymentProcessing);
  const almaEligibility = useSelector((state) => state.almaState.eligibility);
  const almaPaymentProcessing = useSelector((state) => state.almaState.paymentProcessing);
  const [selectedFacility, setSelectedFacility] = useState('1 fois');

  const onSubmit = (data) => {
    // console.log('data submitted', data);

    const customerInformation = {
      ...data,
      gender,
      total,
      bumpCheck,
      productBump
    };
    dispatch(saveLocallyCustomerInformation(customerInformation));

    if (!stripe || !elements) {
      return;
    }
    const payload = {
      stripe: stripe,
      customerInformation,
    };
    if (!data.facility || data.facility === '1 fois') {
      const stripeElement = elements.getElement(CardElement);
      dispatch(launchCardStripeProcessing({...payload, type: 'card', card: stripeElement}))
    } else {
      dispatch(createAlmaPayment({...data, gender}));
    }
  }

  useEffect(() => {
    const finalAmount = product.price + (bumpCheck ? productBump.price : 0);
    setTotal({
      total: product.price,
      finalAmount: finalAmount,
      percent: Math.round(100 - (finalAmount * 100) / product.price)
    });
  }, [product, bumpCheck]);

  const focusOnFirstFieldsWithError = () => setFocus(orderedRequiredFieldsForCheckAlma.filter(field => errors[field])[0]);

  const retrieveAllAlmaRequiredFields = () => orderedRequiredFieldsForCheckAlma.reduce((agg, field) => {
    agg[field] = getValues(field);
    return agg;
  }, {});

  const checkFacilityEligibility = async (facility) => {
    setSelectedFacility(facility);

    if (facility !== '1 fois') {
      let hasNoIssue = await trigger(orderedRequiredFieldsForCheckAlma);
      if (hasNoIssue) {
        dispatch(checkAlmaEligibility(retrieveAllAlmaRequiredFields()));
      } else {
        focusOnFirstFieldsWithError(orderedRequiredFieldsForCheckAlma)
      }
    } else {
      clearErrors();
      dispatch(resetAlmaEligibility());
    }
  }

  useEffect(() => {
    let enable = false;
    if (isDirty) {
      enable = true;
    }
    if (almaEligibility) {
      enable = almaEligibility.eligible;
    }
    if (!almaEligibility) {
      enable = enable && bankInformationFulfilled;
    }
    setDisableSubmit(!(enable && !stripePaymentProcessing && !almaPaymentProcessing));
  }, [isDirty, almaEligibility, bankInformationFulfilled, stripePaymentProcessing, almaPaymentProcessing])


  return (
    <form id="payment-form" className={className} onSubmit={handleSubmit(onSubmit)}>
      <Seller form={{register, errors}} isSellerNeeded={product.salerActiv} isRenewal={false}/>
      <CustomerIdentity form={{register, errors, setValue}} isFacebookNeeded={false}/>
      <CustomerAddress form={{register, errors, control, setValue}}/>
      {!!productBump.code && <CardBump productBump={productBump} handleBumpCheck={handleBumpCheck}/>}
      <RecapCommand product={product} productBump={productBump} bumpCheck={bumpCheck} total={total}/>

      { product.almaActiv &&
        <PaymentAlmaFacility maxMonths={8} defaultFacility={selectedFacility} displayCondition={true}
                             handleFacility={checkFacilityEligibility} control={control}/>
      }
      {
        selectedFacility === '1 fois' && <CardInformation shouldDisplay={true}/>
      }

      <SuccessRedirection />

      <br/>
      <CGV/>
      <br/>
      <button disabled={disableSubmit} id='submit'>
        <span id='button-text'>
          {stripePaymentProcessing || almaPaymentProcessing ?
            <div className='spinner' id='spinner'></div> : 'Procéder au paiement'}
        </span>
      </button>
    </form>
  )
}

export default CheckoutAutoForm;
