import {ofType} from "redux-observable";
import {
  createSubscriptionBasedOnSepaSucceeded,
  LAUNCH_CARD_STRIPE_PROCESSING,
  LAUNCH_SEPA_STRIPE_PROCESSING,
  launchStripeProcessingError,
  REQUEST_PAYMENT_CREATION,
  requestPaymentCreation,
  STRIPE_PAYMENT_SUCCEEDED,
  stripePaymentSucceeded
} from "./stripeActions";
import axios from "axios";
import {urlBdd} from "../../configuration";
import {filter, from, map, switchMap, withLatestFrom} from "rxjs";
import {allFormule, allOffers, allPaymentType} from "../../offers/products/productsWithSeller";
import {productsBuyableAuto} from "../../offers/products/productsBuyableAuto";

const isSaleAuto = (product) => !!productsBuyableAuto[product.code];

const isRenewal = (product) => !isSaleAuto(product) && product.includes('Renouvellement');

const isOneShotPayment = (formule) => formule.commitment === allFormule.TWO_WEEKS

const isAFormation = (product) => product.includes('Formation');

const isAlphaCredit = (formule) => formule.code.includes("X");

const isPhenixRenewal = (formule) => ["VHM_P", "VFM_P"].some(f => formule.code === f);

const isRecurringPayment = ({product, formule}) => !isSaleAuto(product) && !isRenewal(product) && !isOneShotPayment(formule) || isAlphaCredit(formule) || isPhenixRenewal(formule);

export const launchCardStripeProcessing$ = (actions$, state$) => actions$.pipe(
  ofType(LAUNCH_CARD_STRIPE_PROCESSING),
  withLatestFrom(state$),
  switchMap(([action, state]) => from(createPaymentMethod(state.offerState, action.payload))),
  map((result) => result.error ?
    launchStripeProcessingError(result.error.message) : requestPaymentCreation(result)),
)

export const requestRecurringPaymentCreation$ = (actions$, state$) => actions$.pipe(
  ofType(REQUEST_PAYMENT_CREATION),
  withLatestFrom(state$),
  filter(([action, state]) => isRecurringPayment(state.offerState)),
  switchMap(([action, state]) => from(requestRecurringCardPaymentIntentCreation(action.payload, state.customerState, state.offerState))),
  map((paymentIntent) => paymentIntent.error ?
    launchStripeProcessingError(paymentIntent.error) : stripePaymentSucceeded(paymentIntent)),
)

export const requestOneShotPaymentCreation$ = (actions$, state$) => actions$.pipe(
  ofType(REQUEST_PAYMENT_CREATION),
  withLatestFrom(state$),
  filter(([action, state]) => !isRecurringPayment(state.offerState) && !isSaleAuto(state.offerState.product)),
  switchMap(([action, state]) => from(requestOneShotCardPaymentIntentCreation(action.payload, state.customerState, state.offerState))),
  map((response) => response.error ?
    launchStripeProcessingError(response.error) : stripePaymentSucceeded({...response.intent, customer: response.cus_id,})),
)

export const requestSaleAutoPaymentCreation$ = (actions$, state$) => actions$.pipe(
  ofType(REQUEST_PAYMENT_CREATION),
  withLatestFrom(state$),
  filter(([action, state]) => isSaleAuto(state.offerState.product)),
  switchMap(([action, state]) => from(requestSaleAutoCardPaymentIntent(action.payload))),
  map((response) => response.error ?
    launchStripeProcessingError(response.error) : stripePaymentSucceeded({...response.intent, customer: response.cus_id,})),
)

export const launchSepaStripeProcessing$ = (actions$, state$) => actions$.pipe(
  ofType(LAUNCH_SEPA_STRIPE_PROCESSING),
  withLatestFrom(state$),
  switchMap(([action, state]) => from(requestSepaSetupIntentCreation(state.offerState, action.payload, state.customerState, state.offerState))),
  switchMap(({setupIntent, payload}) => from(confirmSepaDebitPayment(setupIntent, payload))),
  map((response) => response.error ?
    launchStripeProcessingError(response.error) : stripePaymentSucceeded(response)),
)

export const createSubscriptionAfterSepaPayment$ = (actions$, state$) => actions$.pipe(
  ofType(STRIPE_PAYMENT_SUCCEEDED),
  withLatestFrom(state$),
  filter(([action, state]) => state.customerState.paymentType === allPaymentType.SEPA),
  switchMap(([action, state]) => from(createSubscriptionBasedOnSepa(state.offerState, state.customerState, action.payload))),
  map((response) => createSubscriptionBasedOnSepaSucceeded(response.data))
)

const createPaymentMethod = async ({product, formule}, {type, stripe, card, customerInformation}) => {
  const {error, paymentMethod} = await stripe.createPaymentMethod({
    type: type,
    card: card,
    billing_details: {
      email: customerInformation.email,
      phone: customerInformation.phone,
      name: `${customerInformation.firstname} ${customerInformation.lastname}`,
      address: {
        city: customerInformation.city,
        country: customerInformation.country,
        line1: customerInformation.address,
        postal_code: customerInformation.zip,
      }
    }
  });

  /*
    TODO: fait pour différentier les produit auto des produits vendu par un vendeur
    REFACTORER CA POUR METTRE LES PRODUTI AUTO SOUS LE MEME FORMAT
   */
  if(allOffers[product]) {
    return {formuleCode: formule.code, paymentMethod, error};
  }
  return {formuleCode: `${product.code}-${customerInformation.gender}`, paymentMethod, error, productBump: customerInformation.productBump};
}

const requestRecurringCardPaymentIntentCreation = async ({formuleCode, paymentMethod}, customerEntries, {product}) => {
  const productProjectionLite = {...allOffers[product]};
  delete productProjectionLite.formules;

  const {data} = await axios({
    method: 'post',
    url: `${urlBdd}/graphQl`,
    data: {
      query: `
        mutation createPaymentIntent($items: String!, $email: String, $name: String, $pm: String, $address: String, $city: String, $country: String, $zip: String, $customerEntries: JSON, $product: JSON){
            createPaymentIntent(items:$items, email:$email, name:$name, pm: $pm, address: $address, city: $city, country: $country, zip: $zip, customerEntries: $customerEntries, product: $product){
                id
                customer
                latest_invoice
                price
                status
                client_secret
                error
            }
        }
        `,
      variables: {
        items: formuleCode,
        email: paymentMethod.billing_details.email,
        name: paymentMethod.billing_details.name,
        pm: paymentMethod.id,
        address: paymentMethod.billing_details.address.line1,
        city: paymentMethod.billing_details.address.city,
        country: paymentMethod.billing_details.address.country,
        zip: paymentMethod.billing_details.address.postal_code,
        customerEntries: {...customerEntries, requestDate: new Date()},
        product: productProjectionLite
      },
    },
  });

  return data.data.createPaymentIntent;
}

const requestSepaSetupIntentCreation = async ({formule},  payload, customerEntries, {product}) => {
  const productProjectionLite = {...allOffers[product]};
  delete productProjectionLite.formules;
  const {customerInformation} = payload;

  const {data} = await axios({
    method: 'post',
    url: `${urlBdd}/graphQl`,
    data: {
      query: `
        mutation createSepaSetupIntent($items: String!, $email: String, $name: String, $address: String, $city: String, $country: String, $zip: String, $customerEntries: JSON, $product: JSON){
            createSepaSetupIntent(items:$items, email:$email, name:$name, address: $address, city: $city, country: $country, zip: $zip, customerEntries: $customerEntries, product: $product){
                id
                customer
                last_payment_error
                payment_method
                client_secret
                currency
                amount
                status
            }
        }
        `,
      variables: {
        items: formule.code,
        email: customerInformation.email,
        name: `${customerInformation.firstname} ${customerInformation.lastname}`,
        address: customerInformation.address,
        city: customerInformation.city,
        country: customerInformation.country,
        zip: customerInformation.zip,
        customerEntries: {...customerEntries, requestDate: new Date()},
        product: productProjectionLite
      },
    },
  });

  return {setupIntent: data.data.createSepaSetupIntent, payload};
}

const confirmSepaDebitPayment = async (setupIntent, {stripe, iban, customerInformation}) => {
  const response = await stripe.confirmSepaDebitSetup(setupIntent.client_secret, {
    payment_method: {
      sepa_debit: iban,
      billing_details: {
        email: customerInformation.email,
        phone: customerInformation.phone,
        name: `${customerInformation.firstname} ${customerInformation.lastname}`,
        address: {
          city: customerInformation.city,
          country: customerInformation.country,
          line1: customerInformation.address,
          postal_code: customerInformation.zip,
        }
      }
    }
  });
  return {...response.setupIntent, error: response.error, customer: setupIntent.customer};
}

const createSubscriptionBasedOnSepa = async ({formule}, {email}, paymentIntent) => {
  return axios.post(`${urlBdd}/stripe/generate_sepa_invoice`, {
    formule: formule.code, // TODO ne va pas fonctionner avec les produits oneTime
    paymentIntent: {...paymentIntent, email}
  });
}

const requestOneShotCardPaymentIntentCreation = async ({formuleCode, paymentMethod}, customerEntries, {product}) => {
  const productProjectionLite = {...allOffers[product]};
  delete productProjectionLite.formules;

  const {data} = await axios({
    method: 'post',
    url: `${urlBdd}/graphQl`,
    data: {
      query: `
        mutation createPaymentIntentSalesRenewal($items: String!, $email: String, $name: String, $pm: String, $address: String, $city: String, $country: String, $zip: String, $customerEntries: JSON, $product: JSON){
          createPaymentIntentSalesRenewal(items:$items, email:$email, name:$name, pm: $pm, address: $address, city: $city, country: $country, zip: $zip, customerEntries: $customerEntries, product: $product){
                id
                cus_id
                intent
                invoceId
            }
        }`,
      variables: {
        items: formuleCode,
        email: paymentMethod.billing_details.email,
        name: paymentMethod.billing_details.name,
        pm: paymentMethod.id,
        address: paymentMethod.billing_details.address.line1,
        city: paymentMethod.billing_details.address.city,
        country: paymentMethod.billing_details.address.country,
        zip: paymentMethod.billing_details.address.postal_code,
        customerEntries: {...customerEntries, requestDate: new Date()},
        product: productProjectionLite
      },
    },
  });

  return data.data.createPaymentIntentSalesRenewal;
}

const requestSaleAutoCardPaymentIntent = async ({formuleCode, paymentMethod, productBump}) => {
  const {data} = await axios({
    method: 'post',
    url: `${urlBdd}/graphQl`,
    data: {
      query: `
        mutation createPaymentIntentSalesAuto($items: String!,$productBump: String, $email: String, $name: String, $pm: String, $address: String, $city: String, $country: String, $zip: String){
          createPaymentIntentSalesAuto(items:$items, productBump: $productBump,email:$email, name:$name, pm: $pm, address: $address, city: $city, country: $country, zip: $zip){
                id
                cus_id
                intent
                invoceId
            }
        }`,
      variables: {
        items: formuleCode,
        productBump: productBump ? productBump.code : undefined,
        email: paymentMethod.billing_details.email,
        name: paymentMethod.billing_details.name,
        pm: paymentMethod.id,
        address: paymentMethod.billing_details.address.line1,
        city: paymentMethod.billing_details.address.city,
        country: paymentMethod.billing_details.address.country,
        zip: paymentMethod.billing_details.address.postal_code,
      },
    },
  });

  return data.data.createPaymentIntentSalesAuto;
}