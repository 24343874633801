import {combineEpics} from "redux-observable";
import {getAllSellers$, postSaleToIntegromat$, saveTheSale$} from "./sales/salesEpics";
import {catchError} from "rxjs";
import {combineReducers} from "redux";
import {offersReducer} from "./offers/offersReducer";
import {getCustomerInformation$, upsertCustomer$} from "./customer/customerEpics";
import {salesReducer} from "./sales/salesReducer";
import {customerReducer} from "./customer/customerReducer";
import {
  checkAlmaEligibility$,
  createAlmaPayment$,
  getAlmaInformation$,
  saleRedirectionAfterAlmaCreatePayment$,
} from "./payment/alma/almaEpics";
import {almaReducer} from "./payment/alma/almaReducer";
import {
  createSubscriptionAfterSepaPayment$,
  launchCardStripeProcessing$,
  launchSepaStripeProcessing$,
  requestRecurringPaymentCreation$,
  requestOneShotPaymentCreation$,
  requestSaleAutoPaymentCreation$,
} from "./payment/stripe/stripeEpics";
import {stripeReducer} from "./payment/stripe/stripeReducer";
import {paymentSuccess$} from "./payment/paymentEpics";
import axios from "axios";
import {lenboxReducer} from "./payment/lenbox/lenboxReducer";
import {createLenboxPayment$, redirectToLenboxPaymentPage$, getLenboxInformation$} from "./payment/lenbox/lenboxEpics";

export const urlBdd = process.env.REACT_APP_API_GRAPHQL_URL;

const epics = [
  getCustomerInformation$,
  upsertCustomer$,
  getAllSellers$,
  checkAlmaEligibility$,
  createAlmaPayment$,
  saleRedirectionAfterAlmaCreatePayment$,
  getAlmaInformation$,
  launchCardStripeProcessing$,
  launchSepaStripeProcessing$,
  requestRecurringPaymentCreation$,
  requestOneShotPaymentCreation$,
  requestSaleAutoPaymentCreation$,
  paymentSuccess$,
  saveTheSale$,
  postSaleToIntegromat$,
  createSubscriptionAfterSepaPayment$,
  createLenboxPayment$,
  redirectToLenboxPaymentPage$,
  getLenboxInformation$,
]

export const rootEpic = (action$, store$, dependencies) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      logErrorToIntegromat(error);
      return source;
    })
  );

export const logErrorToIntegromat = (error) => {
  console.log('TICKET CONTENT', error);

  axios
    .request({
      method: 'POST',
      url: `https://hook.integromat.com/kj85ooud8p0mvfvcb59out1waa7nrncc`,
      headers: {'Content-Type': 'application/json'},
      data: {
        env: process.env.NODE_ENV === 'development' ? 'DEV' : 'PROD',
        error: error.stack
      },
    })
    .then((res) => {
      console.log('TICKET ERROR SEND');
    })
}

export const rootReducer = combineReducers({
  offerState: offersReducer,
  salesState: salesReducer,
  customerState: customerReducer,
  almaState: almaReducer,
  stripeState: stripeReducer,
  lenboxState: lenboxReducer,
});
