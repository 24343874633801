import React from 'react';
import Link from "@material-ui/core/Link";

const CGV = () => {
  return (
    <div className='cgv'>
      En cliquant sur "Proceder au paiement" je reconnais avoir lu et j'accepte&nbsp;
      <Link
        href='https://mentions.alphabody.fr/conditions-generales-de-ventes'
        rel='noopener'
        target='_blank'
      >
        les Conditions Générales de vente
      </Link>
    </div>
  )
}

export default CGV;