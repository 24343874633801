import {
  CARD_INFORMATION_FULFILLED, CREATE_SUBSCRIPTION_BASED_ON_SEPA_SUCCEEDED, IBAN_INFORMATION_FULFILLED,
  LAUNCH_CARD_STRIPE_PROCESSING, LAUNCH_SEPA_STRIPE_PROCESSING,
  LAUNCH_STRIPE_PROCESSING_ERROR, REQUEST_PAYMENT_CREATION,
  STRIPE_PAYMENT_SUCCEEDED, THREE_D_SECURE_ERROR, THREE_D_SECURE_SUCCESS
} from "./stripeActions";
import {RESET_PAYMENT} from "../paymentActions";

const defaultState = {
  bankInformationFulfilled: false,
  paymentProcessing: false,
}

export const stripeReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case IBAN_INFORMATION_FULFILLED:
    case CARD_INFORMATION_FULFILLED:
      return {
        ...state,
        bankInformationFulfilled: action.payload
      }
    case LAUNCH_CARD_STRIPE_PROCESSING:
    case LAUNCH_SEPA_STRIPE_PROCESSING:
      return {
        ...state,
        error: undefined,
        paymentProcessing: true,
        paymentIntent: undefined,
      }
    case REQUEST_PAYMENT_CREATION:
      return {
        ...state,
        paymentMethod: action.payload,
      }
    case LAUNCH_STRIPE_PROCESSING_ERROR:
      return {
        ...state,
        error: action.payload,
        paymentProcessing: false,
        paymentIntent: undefined,
      }
    case STRIPE_PAYMENT_SUCCEEDED:
      return {
        ...state,
        error: undefined,
        customer: action.payload.customer,
        paymentProcessing: !['succeeded', 'processing'].includes(action.payload.status),
        paymentIntent: action.payload,
      }
    case THREE_D_SECURE_SUCCESS:
      return {
        ...state,
        error: undefined,
        paymentProcessing: false,
        paymentIntent: action.payload,
      }
    case THREE_D_SECURE_ERROR:
      return {
        ...state,
        error: action.payload.error.message,
        paymentProcessing: false,
        paymentIntent: action.payload,
      }
    case CREATE_SUBSCRIPTION_BASED_ON_SEPA_SUCCEEDED:
      return {
        ...state,
        error: undefined,
        subscription: action.payload,
      }
    case RESET_PAYMENT:
      return defaultState
    default:
      return state
  }
}