export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const RESET_PAYMENT = 'RESET_PAYMENT';

export const paymentSuccess = () => ({
  type: PAYMENT_SUCCESS,
})
export const resetPayment = (payload) => ({
  type: RESET_PAYMENT,
  payload
})


// TODO: Deleté cette notion dans une PR à part
export const CHANGE_PROCESSING = 'CHANGE_PROCESSING';
export const CHANGE_PAYMENT = 'CHANGE_PAYMENT';

// Valable pour tous les types de paiements
export const ERROR_PROCESSING = 'ERROR_PROCESSING';


export const VENTE_AUTO = 'VENTE_AUTO';

export const changeProcessing = (payload) => ({
  type: CHANGE_PROCESSING,
  payload,
})
export const errorProcessing = (payload) => ({
  type: ERROR_PROCESSING,
  payload,
})


