import {SELECTED_FORMULE, SELECTED_PRODUCT, SELECTED_PRODUCT_AUTO} from "./offersActions";
import {allOffers} from "./products/productsWithSeller";

export const offersReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case SELECTED_PRODUCT_AUTO:
      return {
        renewal: false,
        product: action.payload
      }
    case SELECTED_PRODUCT:
      return {
        ...state,
        renewal: allOffers[action.payload] ? action.payload.includes('Renouvellement') : false, // TODO par du postula qu'un achat de produit oneTime c'est pas un renewal
        upsell: allOffers[action.payload] ? action.payload.includes('Upsell') : false,
        product: action.payload,
        formule: allOffers[action.payload] ?
          allOffers[action.payload].formules.find(f => f.id === allOffers[action.payload].defaultFormule) : {} // TODO pas de formule pour les produit oneTime
      }
    case SELECTED_FORMULE:
      return {
        ...state,
        formule: action.payload
      }
    default:
      return state
  }
}
