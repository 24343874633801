import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Controller} from 'react-hook-form';

const PaymentStripeFacility = ({maxMonths, defaultFacility, handleFacility, control, displayCondition}) => {
  const itemNumberPay = [maxMonths];
  handleFacility(maxMonths);

  return (
    <>
      {displayCondition &&
        <div className='paymentFormCtn'>
          <FormControl className='paymentForm'>
            <InputLabel className='paymentFormLabel' id='paymentOption'>
              Vous souhaitez payer en :
            </InputLabel>

            <Controller control={control} name="facility" rules={{required: true}} defaultValue={defaultFacility}
                        render={({field: {onChange, value, ref}}) => (
                          <Select labelId='paymentOption' id='select-payment'
                                  defaultValue={defaultFacility}
                                  value={defaultFacility}>
                            {
                              itemNumberPay.map(el => <MenuItem key={el} value={el}>{el}</MenuItem>)
                            }
                          </Select>
                        )}
            />
          </FormControl>
        </div>
      }
    </>
  )
}

export default PaymentStripeFacility;
