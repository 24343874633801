import React, {useEffect, useState} from 'react';

import Logo from "../../components/Logo";
import {useStyles} from "../Checkout-auto/styles";
import {allFormule, allOffers, sellableProducts} from "../../store/offers/products/productsWithSeller";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectedFormule, selectedProduct} from "../../store/offers/offersActions";
import {FiCopy, FiExternalLink} from "react-icons/all";
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Alert} from "@mui/lab";
import {Collapse} from "@mui/material";
import {getCustomerInformation} from "../../store/customer/customerActions";

const vipUris = ['OneMoreYear', 'SixAnotherMonths'];

export const encodedUriToSpecialOfferFormuleMapping = ({
  'jHijKvN': allFormule.ONE_MORE_YEAR,
  'mqWknWp': allFormule.SIX_ANOTHER_MONTHS,
})

export const upsellFormuleMapping = (product) => ({
  'OneMoreYear': {
    encoded: 'jHijKvN',
    formule: allOffers[product].formules.find(f => f.commitment === allFormule.ONE_MORE_YEAR),
  },
  'SixAnotherMonths': {
    encoded: 'mqWknWp',
    formule: allOffers[product].formules.find(f => f.commitment === allFormule.SIX_ANOTHER_MONTHS),
  }
})

const AllSpecialOffersUrls = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [email, setEmail] = useState();

  const dispatchThenRoute = (product, formule, myPath) => {
    if (!email) {
      alert('L\'email du client à renouveler est obligatoire.');
    } else {
      dispatch(selectedProduct(product));
      dispatch(selectedFormule(formule));
      dispatch(getCustomerInformation(email));
      navigate(myPath);
    }
  };

  useEffect(() => {
    document.body.classList.add("page-homme");
    return () => {
    };
  }, []);

  const Item = styled(Paper)(({theme}) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: '#EA6639',
    cursor: 'pointer',
    marginBottom: '5px',
  }));

  const handleCopied = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3500);
  }

  return (
    <div className='App center'>
      <div className={classes.root}>
        {<><Collapse in={copied}><Alert severity={"success"}>Copier</Alert></Collapse><br/></>}
        <Logo gender={'homme'}/>
        <div className='routing-table mail'>
          <h2>UPSELL</h2>
          <span>Email du client</span>
          <input value={email} onChange={(e) => setEmail(e.target.value)}/>
        </div>
        {
          vipUris.map(uri =>
            <>
              <Box lg={{flexGrow: 1}}>
                <Grid container spacing={0.5} columns={19}>
                  <Grid item xs={5}>
                    <a onClick={() => {
                      const product = sellableProducts.UPSELL_VIP_HOMME;
                      const formule = upsellFormuleMapping(product)[uri].formule;
                      dispatchThenRoute(product, formule, `/special-offers/${upsellFormuleMapping(product)[uri].encoded}?email=${email}`)
                    }}>
                      <Item>
                        <FiExternalLink style={{marginLeft: '5px', marginRight: '5px'}}/>
                        <span style={{textTransform: 'capitalize'}}>{uri}</span>
                      </Item>
                    </a>
                  </Grid>

                  <Grid item xs={14}>
                    <CopyToClipboard
                      text={`https://paiement.alphabody.fr/special-offers/${upsellFormuleMapping(sellableProducts.UPSELL_VIP_HOMME)[uri].encoded}?email=${email}`}
                      onCopy={() => handleCopied()}>
                      <Item>
                        <FiCopy style={{marginLeft: '5px', marginRight: '5px'}}/>
                        {`https://paiement.alphabody.fr/special-offers/${upsellFormuleMapping(sellableProducts.UPSELL_VIP_HOMME)[uri].encoded}`}
                      </Item>
                    </CopyToClipboard>
                  </Grid>
                </Grid>
              </Box>
            </>
          )
        }
      </div>
    </div>
  );
}

export default AllSpecialOffersUrls;