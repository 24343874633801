import React, {useEffect} from 'react';
import {Triangle} from 'react-loader-spinner'
import MuiAlert from '@material-ui/lab/Alert'
import './finishAlma.css';
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getAlmaPaymentInfos, setAlmaError} from "../../store/payment/alma/almaActions";
import {paymentSuccess} from "../../store/payment/paymentActions";

// TODO retester le process Alma et prendre en compte tous les status
const FinishAlma = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const almaPaymentInfos = useSelector((state) => state.almaState.paymentInfos);

  const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />

  useEffect(() => {
    dispatch(getAlmaPaymentInfos(searchParams.get('pid')))
  }, [searchParams]);

  const almaStatusMapping = {
    "not_started": {
      message: "Le paiement a été créé par Alma, mais les informations du client n'ont pas encore été renseignés.",
      severity: "warning"
    },
    "scored_no": {
      message: "Le paiement a été refusé par Alma, pour un crédit score trop bas (Redirection dans 5sec)",
      severity: "error"
    },
    "score_yes": {
      message: "Le paiement va pouvoir être créé par Alma, le crédit score est assez haut",
      severity: "success"
    },
    "score_maybe": {
      message: "La création du paiement ne peut pas se faire, Alma a besoin de plus d'information sur le client",
      severity: "warning"
    },
    "in_progress": {
      message: "Le paiement est passé avec succès (Redirection dans 5sec)",
      severity: "success"
    },
    "paid": {
      message: "Le paiement est passé avec succès (Redirection dans 5sec)",
      severity: "success"
    }
  }

  // http://localhost:3000/finishAlma?pid=payment_11vq5VJhWNDsDZJE5kzb8sdccEbE5gtgMt in_progress
  // http://localhost:3000/finishAlma?pid=payment_11vqPLO7JWHoA7t6EA6sythxpcoeA4djZU not_started
  useEffect(() => {
    if (almaPaymentInfos) {
      if (almaPaymentInfos.state === "paid" || almaPaymentInfos.state === "in_progress") {
        dispatch(paymentSuccess());
        setTimeout(() => navigate('/success'), 5000);
      } else if (almaPaymentInfos.state === "scored_no") {
        dispatch(setAlmaError("Le paiement Alma n'est pas passé, merci d'utiliser le paiement en 1 fois"));
        setTimeout(() => navigate(almaPaymentInfos.orders[0].data.back_url), 100);
      }
    }
  }, [almaPaymentInfos])

  const triangle = () => {
    return (
      <div className='triangle'>
        <Triangle
          color='#E9570D'
          ariaLabel='loading'
        />
      </div>)
  }

  return (
    <div className="success">
      <div className="container">
        {
          almaPaymentInfos &&
          <>
            <Alert severity={almaStatusMapping[almaPaymentInfos.state].severity}>
              {almaStatusMapping[almaPaymentInfos.state].message}
            </Alert>
            <br/>
            <div className="bandeau">
              <div>{triangle()}</div>
              <div>{['paid', 'in_progress'].includes(almaPaymentInfos.state) ?
                'Préparation de votre commande' : 'Vérification du paiement'}</div>
            </div>
          </>
        }
        {
          !almaPaymentInfos &&
          <>
            <Alert severity={'error'}>Aucun processus de paiment en cours avec cet identifiant de paiement</Alert>
          </>
        }
      </div>
    </div>
  )
}

export default FinishAlma;
