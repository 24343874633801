import React, {useEffect, useState} from 'react';

import Logo from "../../components/Logo";
import {useStyles} from "../Checkout-auto/styles";
import {
  allFormule,
  allOffers,
  allPaymentProvider,
  sellableProducts
} from "../../store/offers/products/productsWithSeller";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectedFormule, selectedProduct} from "../../store/offers/offersActions";
import {FiCopy, FiExternalLink} from "react-icons/all";
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Alert} from "@mui/lab";
import {Collapse} from "@mui/material";

const gender = {
  homme: 'homme',
  femme: 'femme',
}

const vipOffersAvailableByGender = {
  'homme': ['Annuel', 'Semestriel', 'Trimestriel', 'Mensuel', 'TwoWeeks'],
  'femme': ['Annuel', 'Semestriel', 'Trimestriel', 'Mensuel']
}

export const encodedUriToProductFormuleWithAlphabodyCreditMapping = ({
  'derniere-chance-2ycad': allFormule.ANNUEL,
  'derniere-chance-1zdxc': allFormule.SEMESTRIEL,
  'derniere-chance-Ocxpc': allFormule.TRIMESTRIEL,
})

export const encodedUriToProductFormuleWithAlmaMapping = ({
  'jHijKvN': allFormule.ANNUEL,
  'mqWknWp': allFormule.SEMESTRIEL,
  'mqWknZp': allFormule.SEMESTRIEL,
  'EkSECpB': allFormule.TRIMESTRIEL,
  'EkSECxB': allFormule.TRIMESTRIEL,
  'ryeIavg': allFormule.MENSUEL,
  'ktJLadv': allFormule.TWO_WEEKS,
})

export const encodedUriToProductFormulaWithLenboxMapping = ({
  'jHijKuN': allFormule.ANNUEL,
  'mqWknTp': allFormule.SEMESTRIEL,
  'ryeIavg': allFormule.MENSUEL,
  'EkSECyB': allFormule.TRIMESTRIEL,
})

export const productFormuleMapping = (product) => ({
  'Annuel': {
    formules: [
      {
        name: 'Annuel (Stripe + Alma)',
        encoded: 'jHijKvN',
        formule: allOffers[product].formules.find(f => f.commitment === allFormule.ANNUEL)
      }, {
        name: 'Annuel (Stripe + Lenbox)',
        encoded: 'jHijKuN',
        formule: allOffers[product].formules
          .filter(f => f.commitment === allFormule.ANNUEL)
          .find(f => f.facilityPaymentProvider === allPaymentProvider.LENBOX)
      },
      {
        name: 'Annuel (Stripe 10x - Risque pris par AlphaBody)',
        encoded: 'derniere-chance-2ycad',
        formule: allOffers[product].formules
          .filter(f => f.commitment === allFormule.ANNUEL)
          .find(f => f.facilityPaymentProvider === allPaymentProvider.ALPHACREDIT)
      }
    ]
  },
  'Semestriel': {
    formules: [
      {
        name: 'Semestriel (Stripe + Alma 6x maxi)',
        encoded: 'mqWknWp',
        formule: allOffers[product].formules
          .filter(f => f.commitment === allFormule.SEMESTRIEL)
          .find(f => f.facilityPaymentProvider === allPaymentProvider.ALMA && f.maxFacility === 6)
      },
      {
        name: 'Semestriel (Stripe + Alma Jusqu\'à 12x)',
        encoded: 'mqWknZp',
        formule: allOffers[product].formules
          .filter(f => f.commitment === allFormule.SEMESTRIEL)
          .find(f => f.facilityPaymentProvider === allPaymentProvider.ALMA && f.maxFacility === 12)
      },
      {
        name: 'Semestriel (Stripe + Lenbox)',
        encoded: 'mqWknTp',
        formule: allOffers[product].formules
          .filter(f => f.commitment === allFormule.SEMESTRIEL)
          .find(f => f.facilityPaymentProvider === allPaymentProvider.LENBOX)
      },
      {
        name: 'Semestriel (Stripe 10x - Risque pris par AlphaBody)',
        encoded: 'derniere-chance-1zdxc',
        formule: allOffers[product].formules
          .filter(f => f.commitment === allFormule.SEMESTRIEL)
          .find(f => f.facilityPaymentProvider === allPaymentProvider.ALPHACREDIT)
      }]
  },
  'Trimestriel': {
    formules: [
      {
        name: 'Trimestriel (Stripe + Alma 3x maxi)',
        encoded: 'EkSECpB',
        formule: allOffers[product].formules
          .filter(f => f.commitment === allFormule.TRIMESTRIEL)
          .find(f => f.maxFacility === 3)
      },
      {
        name: 'Trimestriel (Stripe + Alma Jusqu\'à 8x)',
        encoded: 'EkSECxB',
        formule: allOffers[product].formules
          .filter(f => f.commitment === allFormule.TRIMESTRIEL)
          .find(f => f.maxFacility === 8)
      },
      {
        name: 'Trimestriel (Stripe + Lenbox)',
        encoded: 'EkSECyB',
        formule: allOffers[product].formules
          .filter(f => f.commitment === allFormule.TRIMESTRIEL)
          .find(f => f.facilityPaymentProvider === allPaymentProvider.LENBOX)
      }]
  },
  'Mensuel': {
    formules: [
      {
        name: 'Mensuel (Stripe)',
        encoded: 'ryeIavg',
        formule: allOffers[product].formules.find(f => f.commitment === allFormule.MENSUEL)
      }
    ]
  },
  'TwoWeeks': {
    formules: [
      {
        name: '2 Semaines d\'essai (Stripe)',
        encoded: 'ktJLadv',
        formule: allOffers[product].formules.find(f => f.commitment === allFormule.TWO_WEEKS)
      }
    ]
  }
})

const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#EA6639',
  cursor: 'pointer',
  marginTop: '5px'
}));

const FragmentUrls = ({gender, style}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const product = gender === 'homme' ? sellableProducts.VIP_HOMME : sellableProducts.VIP_FEMME;

  const dispatchThenRoute = (product, formule, myPath) => {
    dispatch(selectedProduct(product));
    dispatch(selectedFormule(formule.formule));
    navigate(myPath);
  };

  const handleCopied = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3500);
  }

  return (
    <div className={classes.root} style={style}>
      {<><Collapse in={copied}><Alert severity={"success"}>Copier</Alert></Collapse><br/></>}
      <Logo gender={gender}/>
      {
        vipOffersAvailableByGender[gender].map(uri => productFormuleMapping(product)[uri].formules.map(formule =>
          <>
            <h4>
              {formule.name}
              <a onClick={() => {
                dispatchThenRoute(product, formule, `/vip-${gender}/${formule.encoded}`)
              }}>
                <FiExternalLink style={{marginLeft: '5px', marginRight: '5px', cursor: 'pointer'}}/>
              </a>
              <CopyToClipboard text={`${window.location.origin}/vip-${gender}/${formule.encoded}`} onCopy={() => handleCopied()}>
                <Item>
                  <FiCopy style={{marginLeft: '5px', marginRight: '5px'}}/>
                  {`${window.location.origin}/vip-${gender}/${formule.encoded}`}
                </Item>
              </CopyToClipboard>
            </h4>
          </>
        ))
      }
    </div>
  )
}

const AllUrls = () => {

  useEffect(() => {
    document.body.classList.add("page-neutre");
  }, []);

  return (
    <div className='App center'>
      <FragmentUrls gender={gender.homme}/>

      <FragmentUrls gender={gender.femme} style={{marginLeft: '50px'}}/>
    </div>
  );
}

export default AllUrls;
