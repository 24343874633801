import {GET_ALMA_PAYMENT_INFOS_SUCCEEDED} from "../alma/almaActions";

export const REDIRECT_TO_LENBOX_PAYMENT_PAGE = "REDIRECT_TO_LENBOX_PAYMENT_PAGE"
export const CREATE_LENBOX_PAYMENT = "CREATE_LENBOX_PAYMENT"
export const UPDATE_LENBOX_ERROR = "UPDATE_LENBOX_ERROR"
export const GET_LENBOX_PAYMENT_INFOS = "GET_LENBOX_PAYMENT_INFOS"
export const GET_LENBOX_PAYMENT_INFOS_SUCCEEDED = "GET_LENBOX_PAYMENT_INFOS_SUCCEEDED"

export const redirectToLenboxPaymentPage = (payload) => ({
    type: REDIRECT_TO_LENBOX_PAYMENT_PAGE,
    payload
})

export const createLenboxPayment = (payload) => ({
    type: CREATE_LENBOX_PAYMENT,
    payload
})

export const updateLenboxError = (payload) => ({
    type: UPDATE_LENBOX_ERROR,
    payload
})

export const getLenboxPaymentInfos = (payload) => ({
    type: GET_LENBOX_PAYMENT_INFOS,
    payload
})

export const getLenboxPaymentInfosSucceeded = (payload) => ({
    type: GET_LENBOX_PAYMENT_INFOS_SUCCEEDED,
    payload
})
