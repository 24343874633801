import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useStripe} from "@stripe/react-stripe-js";
import {threeDsecureError, threeDsecureSuccess} from "../../../../../store/payment/stripe/stripeActions";
import {paymentSuccess} from "../../../../../store/payment/paymentActions";

const SuccessRedirection = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const {paymentMethod, paymentIntent} = useSelector((state) => state.stripeState);

  useEffect(async () => {
      if (paymentIntent) {
        // Same redirection component for card and sepa_debit TODO need to restructure folder
        if (['processing', 'succeeded'].includes(paymentIntent.status)) {
          dispatch(paymentSuccess());
          navigate('/success');
        }
        if (['requires_action', 'requires_confirmation'].includes(paymentIntent.status)) {
          const res = await stripe.confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethod.id,
          })
          if (res.error) {
            dispatch(threeDsecureError(res));
          }
          if (res.paymentIntent) {
            dispatch(threeDsecureSuccess(res.paymentIntent));
          }
        }
      }
    }, [paymentIntent]
  )

  return (<></>)
}

export default SuccessRedirection;