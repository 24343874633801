import {
  GET_CUSTOMER_INFORMATION_FAILED,
  GET_CUSTOMER_INFORMATION_SUCCEEDED,
  SAVE_LOCALLY_CUSTOMER_INFORMATION,
  UPSERT_CUSTOMER_SUCCEEDED
} from "./customerActions";

export const customerReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case GET_CUSTOMER_INFORMATION_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
        error: undefined
      }
    case GET_CUSTOMER_INFORMATION_FAILED:
      return {
        ...state,
        error: action.payload
      }
    case SAVE_LOCALLY_CUSTOMER_INFORMATION:
      return {
        ...state,
        ...action.payload
      }
    case UPSERT_CUSTOMER_SUCCEEDED:
      return {
        ...state,
        id: action.payload
      }
    default:
      return state
  }
}
