import "./style.scss";
import {useStyles} from "./styles.js";

import React, {useEffect, useState} from "react";
import Gender from "../../components/Gender";
import {useSelector} from "react-redux";
import Logo from "../../components/Logo";
import SecureIcons from "../../components/SecureIcons";
import CheckoutAutoForm from "../../components/CheckoutForm/autoForm/checkoutAutoForm";

const CheckoutAuto = () => {
  const classes = useStyles();
  const [gender, setGender] = useState();
  const {product} = useSelector((state) => state.offerState);

  useEffect(() => {
    if (gender === "homme") {
      document.body.classList.add("page-homme");
    } else if (gender === "femme") {
      document.body.classList.add("page-femme");
    } else {
      document.body.classList.add("page-neutre");
    }
    return () => {
      document.body.classList.remove("page-homme");
      document.body.classList.remove("page-femme");
      document.body.classList.remove("page-neutre");
    };
  }, [gender]);

  return (
    <div className="App center">
      {!gender ? (
        <div className={classes.root}>
          <Gender handleGenderChange={setGender}/>
        </div>
      ) : (
        <div className={classes.root}>
          <Logo gender={gender}/>
          <h1>{product.name}</h1>
          <CheckoutAutoForm classes={classes} gender={gender} product={product}/>
          <br/>
          <SecureIcons/>
        </div>
      )}
    </div>
  );
};

export default CheckoutAuto;
