export const CARD_INFORMATION_FULFILLED = 'CARD_INFORMATION_FULFILLED';
export const IBAN_INFORMATION_FULFILLED = 'IBAN_INFORMATION_FULFILLED';
export const LAUNCH_CARD_STRIPE_PROCESSING = 'LAUNCH_CARD_STRIPE_PROCESSING';
export const LAUNCH_SEPA_STRIPE_PROCESSING = 'LAUNCH_SEPA_STRIPE_PROCESSING';
export const REQUEST_PAYMENT_CREATION = 'REQUEST_PAYMENT_CREATION';
export const LAUNCH_STRIPE_PROCESSING_ERROR = 'LAUNCH_STRIPE_PROCESSING_ERROR';
export const ANALYSE_STRIPE_RESPONSE = 'ANALYSE_STRIPE_RESPONSE';
export const STRIPE_PAYMENT_SUCCEEDED = 'STRIPE_PAYMENT_SUCCEEDED';
export const THREE_D_SECURE_SUCCESS = 'THREE_D_SECURE_SUCCESS';
export const THREE_D_SECURE_ERROR = 'THREE_D_SECURE_ERROR';
export const CREATE_SUBSCRIPTION_BASED_ON_SEPA_SUCCEEDED = 'CREATE_SUBSCRIPTION_BASED_ON_SEPA_SUCCEEDED';

export const ibanInformationFulfilled = (payload) => ({
  type: IBAN_INFORMATION_FULFILLED,
  payload,
})
export const cardInformationFulfilled = (payload) => ({
  type: CARD_INFORMATION_FULFILLED,
  payload,
})
export const requestPaymentCreation = (payload) => ({
  type: REQUEST_PAYMENT_CREATION,
  payload,
})
export const launchCardStripeProcessing = (payload) => ({
  type: LAUNCH_CARD_STRIPE_PROCESSING,
  payload,
})
export const launchSepaStripeProcessing = (payload) => ({
  type: LAUNCH_SEPA_STRIPE_PROCESSING,
  payload,
})
export const launchStripeProcessingError = (payload) => ({
  type: LAUNCH_STRIPE_PROCESSING_ERROR,
  payload,
})
export const analyseStripeResponse = (payload) => ({
  type: ANALYSE_STRIPE_RESPONSE,
  payload,
})
export const stripePaymentSucceeded = (payload) => ({
  type: STRIPE_PAYMENT_SUCCEEDED,
  payload,
})
export const threeDsecureSuccess = (payload) => ({
  type: THREE_D_SECURE_SUCCESS,
  payload,
})
export const threeDsecureError = (payload) => ({
  type: THREE_D_SECURE_ERROR,
  payload,
})
export const createSubscriptionBasedOnSepaSucceeded = (payload) => ({
  type: CREATE_SUBSCRIPTION_BASED_ON_SEPA_SUCCEEDED,
  payload,
})
