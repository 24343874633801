import React from 'react';
import {BsCheck} from 'react-icons/bs';
import logoFemme from '../../images/LOGO+SEUL+FEMME.png';
import logoHomme from '../../images/LOGO+SEUL+HOMME.png';
import {useSelector} from "react-redux";

const Programme = ({gender, isVip}) => {
  const isRenewal = useSelector((state) => state.offerState.renewal);

  const newCustomerProgram = [
    `Ton Programme d'entraînement en 3 fois par semaine`,
    `Ton plan de nutrition personnalisé`,
    `Ton programme de secours à la maison au poids de corps`,
    `Guide comment limiter la casse au resto`,
    `Détail technique de tous les exercices`,
    `Ta liste de course hebdomadaire`,
    `Ton livre de recette`,
    `Vidéos et emails explicatifs chaque jour`,
    `Accès à la communauté Facebook AlphaBody`,
    `Questions / Réponses en direct hebdomadaire`,
    `Vidéo live journalière sur le groupe`,
    `Et bien plus encore`,
  ];
  let displayProgram = newCustomerProgram;

  if (isRenewal) {
    if (isVip === true) {
      displayProgram = [
        `Un coach qui t'accompagne au quotidien`,
        `Des Programmes hebdomadaires d'entraînement en salle et à la maison`,
        `Des vidéos explicatives des exercices à réaliser.`,
        `Des plans de nutrition adaptés à ta morphologie et à tes objectifs`,
        `Des listes de courses`,
        `Vidéos et emails explicatifs`,
        `Accès à la communauté Facebook AlphaBody`,
        `Des multitudes de vidéos et publications sur le groupe Facebook`,
        `Des lives à Thème animés par nos coachs avec parfois des invités exceptionnels`,
        `Et bien plus encore.`,
      ];
    } else if (isVip === false) {
      displayProgram = [
        `Des Programmes hebdomadaires d'entraînement en salle et à la maison`,
        `Des vidéos explicatives des exercices à réaliser.`,
        `Des plans de nutrition adaptés à ta morphologie et à tes objectifs`,
        `Des listes de courses`,
        `Vidéos et emails explicatifs `,
        `Accès à la communauté avec des ambassadeurs pour t'accompagner et te guider`,
        `Des multitudes de vidéos et publications sur le groupe FaceBook`,
        `Et bien plus encore.`,
      ];
    }
  }

  return (
    <div>
      <div className='recap'>Récapitulatif</div>
      {displayProgram.map((elm) => {
        return (
          <div className='checkList' key={elm}>
            <BsCheck/> {elm}
          </div>
        );
      })}
      <div>
        {' '}
        <img
          src={gender === 'homme' ? logoHomme : logoFemme}
          alt='logo'
          className='logo-triangle'
        />
      </div>
    </div>
  );
};

export default Programme;
