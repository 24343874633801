export const productsBuyableAuto = {
  "7dc": {
    id: 6,
    code: "7dc",
    name: "Challenge 7 Jours",
    price: 7,
    crossedOutPrice: 14,
    almaActiv: false,
    salerActiv: false,
  },
  "28dc": {
    id: 5,
    code: "28dc",
    name: "Challenge 28 Jours",
    price: 47,
    crossedOutPrice: 94,
    almaActiv: false,
    salerActiv: false,
  },
  livreRecettes: {
    id: 7,
    code: "livreRecettes",
    name: "Livre de recettes",
    price: 9,
    crossedOutPrice: 18,
    almaActiv: false,
    salerActiv: false,
  },
  "conf-estime-client": {
    id: 8,
    code: "conf-estime-client",
    name: "Conférence estime de soi (client)",
    price: 29,
    almaActiv: false,
    salerActiv: false,
  },
  "conf-estime-liste": {
    id: 8,
    code: "conf-estime-liste",
    name: "Conférence estime de soi (liste)",
    price: 39,
    almaActiv: false,
    salerActiv: false,
  },
  packEstime: {
    id: 0,
    code: "packEstime",
    name: "Pack super Héro de l'estime de soi",
    price: 297,
    almaActiv: false,
    salerActiv: false,
  },
  immersion: {
    id: 9,
    code: "immersion",
    name: "Week-end Immersion alphabody",
    price: 4000,
    almaActiv: true,
    salerActiv: false,
  },
  bootcamp: {
    id: 10,
    code: "bootcamp",
    name: "Boot-Camp Alphabody",
    price: 2000,
    almaActiv: false,
    salerActiv: false,
  },
  "resa-programme": {
    id: 11,
    code: "resa-programme",
    name: "Réservation Programme Alphabody",
    price: 100,
    almaActiv: false,
    salerActiv: false,
  },
  "resa-immersion": {
    id: 12,
    code: "resa-immersion",
    name: "Réservation Immersion Alphabody",
    price: 100,
    almaActiv: false,
    salerActiv: false,
  },
  "resa-bootcamp": {
    id: 13,
    code: "resa-bootcamp",
    name: "Réservation BootCamp Alphabody",
    price: 100,
    almaActiv: false,
    salerActiv: false,
  },
};
