import React, {useEffect} from 'react';
import TextField from "@material-ui/core/TextField";
import {useSelector} from "react-redux";
import {Alert} from "@material-ui/lab";

const CustomerIdentity = ({form, isFacebookNeeded}) => {
  const {register, errors, setValue} = form;
  const customerInformation = useSelector((state) => state.customerState);
  const {renewal, upsell} = useSelector((state) => state.offerState);

  useEffect(() => {
    if (renewal || upsell) {
      setValue('email', customerInformation.email);
      setValue('lastname', customerInformation.lastname);
      setValue('firstname', customerInformation.firstname);
      setValue('phone', customerInformation.phone);
      setValue('facebook', customerInformation.facebook);
    }
  }, [renewal, upsell, customerInformation]);

  return (
    <div className='inputs' id='identity'>
      <h3>Informations générales</h3>

      <TextField id='Email' label='Email *' variant='outlined'
                 {...register("email", {required: true, pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/})} />
      {errors.email?.type === 'required' && <Alert severity='error'>Merci de renseigner ce champ</Alert>}
      {errors.email?.type === 'pattern' && <Alert severity='error'>Merci de renseigner une adresse mail valide</Alert>}

      <TextField id='Nom' label='Nom *' variant='outlined'
                 {...register("lastname", {required: true})} />
      {errors.lastname?.type === 'required' && <Alert severity='error'>Merci de renseigner ce champ</Alert>}

      <TextField id='Prénom' label='Prénom *' variant='outlined'
                 {...register("firstname", {required: true})} />
      {errors.firstname?.type === 'required' && <Alert severity='error'>Merci de renseigner ce champ</Alert>}

      <TextField id='num' label='Numero de téléphone *' variant='outlined' type={'tel'}
                 {...register("phone", {
                   required: true,
                   pattern: /^[+]?[(]?[0-9\s]{3}[)]?[-\s.]?[0-9\s]{3}[-\s.]?[0-9\s]{4,10}$/im
                 })} />
      {errors.phone?.type === 'required' && <Alert severity='error'>Merci de renseigner ce champ</Alert>}
      {errors.phone?.type === 'pattern' &&
        <Alert severity='error'>Merci de renseigner un numéro de téléphone valide</Alert>}

      {isFacebookNeeded && <TextField id='facebook' label='Nom Facebook *' variant='outlined'
                                      {...register("facebook", {required: true})} />}
      {errors.facebook?.type === 'required' && <Alert severity='error'>Merci de renseigner ce champ</Alert>}

    </div>
  )
}

export default CustomerIdentity;
