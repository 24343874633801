import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Controller} from 'react-hook-form';
import AlmaEligibility from "./almaEligibility";

const PaymentAlmaFacility = ({maxMonths, defaultFacility, handleFacility, control, displayCondition, upsell}) => {
  const itemNumberPay = []
  const impossibleFacilities = [5, 7, 9, 10, 11];
  let numberOfMonth = upsell ? 2 : 1; // TODO: comme pour le moment l'upsell ne se fait que via alma, on retire la facilité en 1 fois
  for (numberOfMonth; numberOfMonth <= maxMonths; numberOfMonth++) {
    if (!impossibleFacilities.includes(numberOfMonth)) {
      itemNumberPay.push(`${numberOfMonth} fois`)
    }
  }

  const handleFacilityChange = (fn, event) => {
    const selectedFacility = event.target.value;
    fn(selectedFacility);
    handleFacility(selectedFacility);
  }

  return (
    <>
      {displayCondition &&
        <div className='paymentFormCtn'>
          <FormControl className='paymentForm'>
            <InputLabel className='paymentFormLabel' id='paymentOption'>
              Vous souhaitez payer en :
            </InputLabel>

            <Controller control={control} name="facility" rules={{required: true}} defaultValue={defaultFacility}
                        render={({field: {onChange, value, ref}}) => (
                          <Select labelId='paymentOption' id='select-payment'
                                  defaultValue={defaultFacility}
                                  onChange={event => handleFacilityChange(onChange, event)}>
                            {
                              itemNumberPay.map(el => <MenuItem key={el} value={el}>{el}</MenuItem>)
                            }
                          </Select>
                        )}
            />
          </FormControl>
          <div style={{marginTop: '15px'}}>
            <AlmaEligibility/>
          </div>
        </div>
      }
    </>
  )
}

export default PaymentAlmaFacility;
