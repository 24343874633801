export const SELECTED_PRODUCT = 'SELECTED_PRODUCT';
export const SELECTED_PRODUCT_AUTO = 'SELECTED_PRODUCT_AUTO';
export const SELECTED_FORMULE = 'SELECTED_FORMULE';

export const selectedProduct = (payload) => ({
    type: SELECTED_PRODUCT,
    payload,
})
export const selectedProductAuto = (payload) => ({
    type: SELECTED_PRODUCT_AUTO,
    payload,
})
export const selectedFormule = (payload) => ({
    type: SELECTED_FORMULE,
    payload,
})