import React, {useEffect} from 'react';
import Logo from '../../components/Logo';
import Programme from '../../components/Programme';
import SecureIcons from '../../components/SecureIcons';
import {makeStyles} from '@material-ui/core/styles';
import CheckoutForm from "../../components/CheckoutForm/checkoutForm";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
}));

const Checkout = ({gender, isVip}) => {
    const classes = useStyles();

    useEffect(() => {
        if (gender === 'homme') {
            document.body.classList.add('page-homme');
        } else if (gender === 'femme') {
            document.body.classList.add('page-femme');
        }
        return () => {
            document.body.classList.remove('page-homme');
            document.body.classList.remove('page-femme');
        };
    }, []);

    return (
        <div className='App'>
            <div className={classes.root}>
                <Logo gender={gender}/>
                <CheckoutForm gender={gender}/>
                <br/>
                <SecureIcons/>
            </div>
            <div>
                <Programme gender={gender} isVip={isVip}/>
            </div>
        </div>
    );
};

export default Checkout;