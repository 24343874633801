import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import PaymentAlmaFacility from "./alma/paymentAlmaFacility";
import CardInformation from "./stripe/cardInformation";
import {Controller} from 'react-hook-form';
import Complements from "../../products/complements";
import {allFormule, allPaymentProvider} from "../../../../store/offers/products/productsWithSeller";
import {selectedFormule} from "../../../../store/offers/offersActions";
import {useDispatch, useSelector} from "react-redux";
import PaymentLenboxFacility from "../lenbox/paymentLenboxFacility";
import PaymentStripeFacility from "./stripe/paymentStripeFacility";

const PaymentPerCard = ({form, product, handleFacilityChange, withComplement}) => {
  const {register, control} = form;
  const dispatch = useDispatch();
  const defaultFacility = "1 fois";
  const [selectedFormuleId, changeSelectedFormuleId] = useState(product.defaultFormule); // TODO Refacto, voir si c'est toujours obligatoire (Sert pour savoir si on doit afficher les compléments)
  const [facility, changeFacility] = useState(defaultFacility);
  const {upsell, formule} = useSelector((state) => state.offerState);

  const handleFormuleChange = (formValueRegistered, value) => {
    formValueRegistered(value);
    changeSelectedFormuleId(parseInt(value));
    changeFacility(defaultFacility);
    const formule = product.formules.find(f => f.id === parseInt(value));
    dispatch(selectedFormule(formule));
  }

  const isFacilityEligible = (formule) => ![allFormule.MENSUEL].includes(formule.commitment) &&
    [allPaymentProvider.ALMA, allPaymentProvider.LENBOX, allPaymentProvider.ALPHACREDIT].includes(formule.facilityPaymentProvider);

  const isAlmaPayment = (formule) => formule.facilityPaymentProvider === allPaymentProvider.ALMA
  const isLenboxPayment = (formule) => formule.facilityPaymentProvider === allPaymentProvider.LENBOX
  const isAlphabodyFacilityPayment = (formule) => formule.facilityPaymentProvider === allPaymentProvider.ALPHACREDIT

  const isFormation = (product) => product.includes('Formation');

  const isFormationProduct = isFormation(product.name);

  useEffect(() => {
    if(isAlmaPayment(formule)) { // Only elligibility check for Alma payment
      handleFacilityChange({formule: formule, facility: facility})
    }
  }, [facility]);

  const getPriceLabel = (formule) => withComplement || formule.withComplement ?
    `${formule.priceLabel} avec le complément de ton choix` :
    (<div className='priceLine'><p>{formule.priceLabel}</p>
      {formule.crossPrice ?
        <p className='discountPrice'><strike>{formule.crossPrice}</strike> soit {formule.discount}</p> : ''}
    </div>);

  const shouldDisplayComplement = () => {
    const selectedFormule = product.formules.find(f => f.id === selectedFormuleId);
    return withComplement || selectedFormule.withComplement;
  }

  return (
    <div className='paymentFormCtn'>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Choix de la formule:</FormLabel>
        {
          !isFormationProduct &&
          <RadioGroup>
            {isFacilityEligible(formule) ?
              <>
                <FormControlLabel value={formule.id} control={<Radio checked={true}/>} label={getPriceLabel(formule)}/>
                {
                  isAlmaPayment(formule) &&
                  <>
                    <PaymentAlmaFacility maxMonths={formule.maxFacility} defaultFacility={defaultFacility} upsell={upsell}
                                         displayCondition={true} handleFacility={changeFacility} control={control}/>
                  </>
                }
                {
                  isLenboxPayment(formule) &&
                  <>
                    <PaymentLenboxFacility maxMonths={formule.maxFacility} defaultFacility={defaultFacility} upsell={upsell}
                                         displayCondition={true} handleFacility={changeFacility} control={control}/>
                  </>
                }
                {
                  isAlphabodyFacilityPayment(formule) &&
                  <>
                  <PaymentStripeFacility maxMonths={formule.maxFacility} defaultFacility={formule.maxFacility}
                                         displayCondition={true} handleFacility={changeFacility} control={control}/>
                  </>
                }
              </>
              :
              <>
                <FormControlLabel value={formule.id} control={<Radio checked={true}/>} label={getPriceLabel(formule)}/>
              </>
            }
          </RadioGroup>
        }
        {
          isFormationProduct &&
          <Controller control={control} name="formule" rules={{required: true}}
                      defaultValue={selectedFormuleId}
                      render={({field: {onChange}}) => (
                        <RadioGroup value={selectedFormuleId}
                                    onChange={(e, value) => handleFormuleChange(onChange, value)}>
                          {product.formules.map(formule => isFacilityEligible(formule) ?
                            <>
                              <FormControlLabel value={formule.id} control={<Radio/>} label={getPriceLabel(formule)}/>
                              <PaymentAlmaFacility maxMonths={formule.maxFacility} defaultFacility={defaultFacility}
                                               displayCondition={selectedFormuleId === formule.id}
                                               handleFacility={changeFacility} control={control}/>
                            </>
                            :
                            <>
                              <FormControlLabel value={formule.id} control={<Radio/>} label={getPriceLabel(formule)}/>
                            </>
                          )}
                        </RadioGroup>)}
          />
        }
      </FormControl>

      {shouldDisplayComplement() && <div><br/><Complements form={{register}}/></div>}
      {!shouldDisplayComplement() && <br/>}

      <CardInformation shouldDisplay={(facility === '1 fois' && !upsell) || (formule && formule.facilityPaymentProvider === allPaymentProvider.ALPHACREDIT)}/>
    </div>)
}

export default PaymentPerCard;
