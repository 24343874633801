import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentPerCard from './card/paymentPerCard';
import {Tabs} from '@material-ui/core';
import {useSelector} from "react-redux";
import PaymentPerSepa from "./sepa/paymentPerSepa";
import {allOffers, allPaymentType} from "../../../store/offers/products/productsWithSeller";

const SelectPaymentType = ({form, handleFacilityChange, handlePaymentTypeChange, withComplement}) => {
  const {register, control} = form;
  const [paymentType, setPaymentType] = useState('CARD');
  const product = allOffers[useSelector((state) => state.offerState.product)];
  const formule = useSelector((state) => state.offerState.formule);

  const paymentTypeChanged = (event, newValue) => {
    setPaymentType(newValue);
    handlePaymentTypeChange(newValue)
  };

  const formuleContainsCardPaymentType = (formule) => formule.methods.includes(allPaymentType.CARD);
  const formuleContainsSepaPaymentType = (formule) => formule.methods.includes(allPaymentType.SEPA);

  return (
    <div className='inputs' id='payment'>
      <h3>Mode de paiement</h3>

      <Box sx={{typography: 'body1'}}>
        <TabContext value={paymentType}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs onChange={paymentTypeChanged} aria-label='payment type selection' value={paymentType}>
              <Tab icon={<CreditCardIcon/>} iconPosition='start' label='Carte de crédit' value='CARD'
                   style={{width: '50%'}}/>
              <Tab icon={<AccountBalanceIcon/>} iconPosition='start' label='SEPA' value='SEPA' style={{width: '50%'}}/>
            </Tabs>
          </Box>

          <TabPanel value='CARD' style={{paddingBottom: '0px'}}>
            {formuleContainsCardPaymentType(formule) &&
              <PaymentPerCard form={{register, control}} product={product}
                              handleFacilityChange={handleFacilityChange}
                              withComplement={withComplement} />
            }
          </TabPanel>

          <TabPanel value='SEPA'>
            {formuleContainsSepaPaymentType(formule) &&
              <PaymentPerSepa form={{register, control}} product={product}
                              withComplement={withComplement} />
            }
            {!formuleContainsSepaPaymentType(formule) &&
              'Le paiement par virement SEPA n\'est pas disponible pour ce produit'
            }
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}

export default SelectPaymentType;
