import React, {useEffect} from "react";
import {getLenboxPaymentInfos} from "../../store/payment/lenbox/lenboxActions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Triangle} from "react-loader-spinner";
import MuiAlert from '@material-ui/lab/Alert'

const FinishLenbox = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const lenboxPaymentInfos = useSelector((state) => state.lenboxState.paymentInfos);

    const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />

    useEffect(() => {
        let pid = searchParams.get('pid');
        if (pid) {
            dispatch(getLenboxPaymentInfos(pid))
        }
    }, [searchParams])

    useEffect(() => {
        if (lenboxPaymentInfos && lenboxPaymentInfos.state === "in_progress") {
            setTimeout(() => navigate('/success'), 5000);
        }
    }, [lenboxPaymentInfos])

    const triangle = () => {
        return (
            <div className='triangle'>
                <Triangle color='#E9570D' ariaLabel='loading'/>
            </div>)
    }

    return (
        <div className="success">
            <div className="container">
                {
                    lenboxPaymentInfos &&
                    <>
                        <Alert severity={'success'}>
                            Le dossier de financement est en cours de traitement (Redirection dans 5sec)
                        </Alert>
                        <br/>
                        <div className="bandeau">
                            <div>{triangle()}</div>
                            <div>{['in_progress'].includes(lenboxPaymentInfos.state) ?
                                'Préparation de votre commande' : 'Vérification du paiement'}</div>
                        </div>
                    </>
                }
                {
                    !lenboxPaymentInfos &&
                    <>
                        <Alert severity={'error'}>Aucun processus de paiment en cours avec cet identifiant de paiement</Alert>
                    </>
                }
            </div>
        </div>
    )

}

export default FinishLenbox;