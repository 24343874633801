export const GET_CUSTOMER_INFORMATION = 'GET_CUSTOMER_INFORMATION';
export const GET_CUSTOMER_INFORMATION_SUCCEEDED = 'GET_CUSTOMER_INFORMATION_SUCCEEDED';
export const GET_CUSTOMER_INFORMATION_FAILED = 'GET_CUSTOMER_INFORMATION_FAILED';
export const SAVE_LOCALLY_CUSTOMER_INFORMATION = 'SAVE_LOCALLY_CUSTOMER_INFORMATION';
export const UPSERT_CUSTOMER = 'UPSERT_CUSTOMER';
export const UPSERT_CUSTOMER_SUCCEEDED = 'UPSERT_CUSTOMER_SUCCEEDED';

export const getCustomerInformation = (payload) => ({
    type: GET_CUSTOMER_INFORMATION,
    payload,
})
export const fulfillCustomerInformation = (payload) => ({
    type: GET_CUSTOMER_INFORMATION_SUCCEEDED,
    payload,
})
export const customerUnknown = (payload) => ({
    type: GET_CUSTOMER_INFORMATION_FAILED,
    payload,
})
export const saveLocallyCustomerInformation = (payload) => ({
    type: SAVE_LOCALLY_CUSTOMER_INFORMATION,
    payload,
})
export const upsertCustomer = () => ({
    type: UPSERT_CUSTOMER,
})
export const upsertCustomerSucceeded = (payload) => ({
    type: UPSERT_CUSTOMER_SUCCEEDED,
    payload,
})
