import './accueil.css'

import React from 'react'
import {selectedProduct} from "../../store/offers/offersActions";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {sellableProducts} from "../../store/offers/products/productsWithSeller";

const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const dispatchThenRoute = (product, myPath) => {
        dispatch(selectedProduct(product));
        navigate(myPath);
    };

    return (
        <div className='routing'>
            <h1 className='title-routing'>
                Choississez la page de paiement à laquelle vous souhaitez accéder
            </h1>
            <div className='routing-table'>
                <h2>COACHING VIP</h2>
                <a className='text-white route btn'
                   onClick={() => dispatchThenRoute(sellableProducts.VIP_HOMME, '/vip-homme/jHijKvN')}>
                    Homme
                </a>
                <a className='text-white route btn fbtn'
                   onClick={() => dispatchThenRoute(sellableProducts.VIP_FEMME, '/vip-femme/jHijKvN')}>
                    Femme
                </a>
            </div>
        </div>
    );
}

export default Home
