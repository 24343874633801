import React from "react";

import IconMan from "./IconMan";
import IconWoman from "./IconWoman"
import './style.css'

const Gender = ({handleGenderChange}) => {
    return (
        <div className='genderContainer'>
            <div className='title-gender'>
                {'Es-tu un homme ou une femme ?'}
            </div>
            <div className='icons'>
                <div className='icon-container' onClick={() => handleGenderChange('homme')}>
                    <div className='icon'>
                        <IconMan
                            circleColor='#F9FBFF'
                            topMainColor='#0070A2'
                            topShadowColor='#01557B'
                            topLightColor='#3095C2'
                        />
                    </div>
                    <p>Homme</p>
                </div>
                <div className='icon-container' onClick={() => handleGenderChange('femme')}>
                    <div className='icon'>
                        <IconWoman
                            circleColor='#F9FBFF'
                            topMainColor='#E88383'
                            topShadowColor='#E26969'
                            topLightColor='#F1A1A1'
                        />
                    </div>
                    <p>Femme</p>
                </div>
            </div>
        </div>
    )
}

export default Gender;