import './stripe.css';
import './style.scss';

import React, {useEffect, useState} from 'react';
import Seller from "./components/seller";
import CustomerIdentity from "./components/customerIdentity";
import CustomerAddress from "./components/customerAddress";
import {useForm} from "react-hook-form";
import SelectPaymentType from "./payment/selectPaymentType";
import CGV from "./components/cgv";
import {useDispatch, useSelector} from "react-redux";
import {checkAlmaEligibility, createAlmaPayment, resetAlmaEligibility} from "../../store/payment/alma/almaActions";
import {CardElement, IbanElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {launchCardStripeProcessing, launchSepaStripeProcessing} from "../../store/payment/stripe/stripeActions";
import {saveLocallyCustomerInformation} from "../../store/customer/customerActions";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    allFormule,
    allOffers,
    allPaymentProvider,
    allPaymentType
} from "../../store/offers/products/productsWithSeller";
import {Alert} from "@mui/lab";
import {createLenboxPayment} from "../../store/payment/lenbox/lenboxActions";

const CheckoutForm = ({gender}) => {
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();
  const classname = gender === 'homme' ? 'border-homme' : 'border-femme';
  const orderedRequiredFieldsForCheckAlma = ["email", "lastname", "firstname", "phone", "address", "zip", "city", "country", "formule", "facility"];
  const {
    register, trigger, formState: {errors, isDirty}, handleSubmit, setFocus, clearErrors, control, getValues, setValue
  } = useForm();
  const almaEligibility = useSelector((state) => state.almaState.eligibility);
  const almaError = useSelector((state) => state.almaState.error);
  const lenboxError = useSelector((state) => state.lenboxState.error);
  const {bankInformationFulfilled} = useSelector((state) => state.stripeState);
  const almaPaymentProcessing = useSelector((state) => state.almaState.paymentProcessing);
  const stripePaymentProcessing = useSelector((state) => state.stripeState.paymentProcessing);
  const lenboxPaymentProcessing = useSelector((state) => state.lenboxState.paymentProcessing);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [paymentType, setPaymentType] = useState(allPaymentType.CARD);
  const {product, formule, renewal} = useSelector((state) => state.offerState);
  const [searchParams] = useSearchParams();
  const withComplement = searchParams.get('comp') ? searchParams.get('comp') === 'true' : false;

  // Utile uniquement dans le cadre d'un renouvellement (Quand l'utilisateur est inconnu)
  const navigate = useNavigate();
  const error = useSelector((state) => state.customerState.error);
  useEffect(() => {
    if(error) {
      navigate('/renewal');
    }
  }, [error]);

  function createCardPayment(data, payload) {
    if ((!data.facility || data.facility === '1 fois') || (formule && formule.facilityPaymentProvider
      && formule.facilityPaymentProvider === allPaymentProvider.ALPHACREDIT)) {
      const stripeElement = elements.getElement(CardElement);
      return dispatch(launchCardStripeProcessing({...payload, type: 'card', card: stripeElement}))
    }
    if (formule && formule.facilityPaymentProvider
        && formule.facilityPaymentProvider === allPaymentProvider.ALMA) {
        return dispatch(createAlmaPayment({...data, gender, formule}))
    }
    if (formule && formule.facilityPaymentProvider
        && formule.facilityPaymentProvider === allPaymentProvider.LENBOX) {
        return dispatch(createLenboxPayment({...data, gender, formule}))
    }
  }

  const onSubmit = (data) => {
    console.log('data submitted', data);

    dispatch(saveLocallyCustomerInformation({
      ...data,
      gender: gender,
      sellerId: data.coachSeller,
      formule,
      paymentType,
    }));

    if (!stripe || !elements) {
      return;
    }
    let payload = {
      stripe: stripe,
      customerInformation: {...data, formule},
    };
    switch (paymentType) {
      case allPaymentType.CARD:
        createCardPayment(data, payload);
        break;
      case allPaymentType.SEPA:
        const stripeElement = elements.getElement(IbanElement);
        dispatch(launchSepaStripeProcessing(
            {
              ...payload,
              type: 'sepa_debit',
              iban: stripeElement
            }
        ));
        break;
    }
  }

  const focusOnFirstFieldsWithError = () => setFocus(orderedRequiredFieldsForCheckAlma.filter(field => errors[field])[0]);

  useEffect(() => {
    focusOnFirstFieldsWithError(orderedRequiredFieldsForCheckAlma)
  }, [errors])

  const retrieveAllAlmaRequiredFields = () => orderedRequiredFieldsForCheckAlma.reduce((agg, field) => {
    agg[field] = getValues(field);
    return agg;
  }, {});

  const checkFacilityEligibility = async ({formule, facility}) => {
    if (formule.commitment !== allFormule.MENSUEL && facility && facility !== '1 fois') {
      let hasNoIssue = await trigger(orderedRequiredFieldsForCheckAlma);
      if (hasNoIssue) {
        dispatch(checkAlmaEligibility(retrieveAllAlmaRequiredFields()));
      } else {
        focusOnFirstFieldsWithError(orderedRequiredFieldsForCheckAlma)
      }
    } else {
      clearErrors();
      dispatch(resetAlmaEligibility());
    }
  }

  const checkPaymentType = (paymentType) => {
    setPaymentType(paymentType);
  }

  useEffect(() => {
    let enable = false;
    if (isDirty) {
      enable = true;
    }
    if (almaEligibility) {
      enable = almaEligibility.eligible;
    }
    if (!almaEligibility) {
      enable = (enable && bankInformationFulfilled) // Condition pour remplir Stripe Card: tous les champs remplis et Sepa + IBAN
        || (enable && formule.facilityPaymentProvider === allPaymentProvider.LENBOX) // Condition pour Lendbox: juste les champs remplis
    }
    setDisableSubmit(!(enable && !stripePaymentProcessing && !almaPaymentProcessing));
  }, [isDirty, almaEligibility, bankInformationFulfilled, stripePaymentProcessing, almaPaymentProcessing, paymentType])

  const [price, setPrice] = useState();
  useEffect(() => {
    setPrice(formule.price);
  }, [product, formule]);

  return (
    <form id='payment-form' className={classname} onSubmit={handleSubmit(onSubmit)}>
      {almaError && <Alert variant='standard' color='error'>{almaError}</Alert>}
      {lenboxError && <Alert variant='standard' color='error'>{lenboxError}</Alert>}
      <Seller form={{register, errors, setValue}} isSellerNeeded={allOffers[product].isSellerNeeded} isRenewal={renewal}/>
      <CustomerIdentity form={{register, errors, setValue}} isFacebookNeeded={true}/>
      <CustomerAddress form={{register, errors, control, setValue}}/>
      <SelectPaymentType form={{register, errors, control}}
                                handleFacilityChange={checkFacilityEligibility}
                                handlePaymentTypeChange={checkPaymentType}
                                withComplement={withComplement} />

      <CGV/>
      <br/>
      <button disabled={disableSubmit} id='submit'>
        <span id='button-text'>
          {stripePaymentProcessing || almaPaymentProcessing || lenboxPaymentProcessing ? <div className='spinner' id='spinner'></div> :
            paymentType === allPaymentType.CARD ? 'Procéder au paiement' : `Confirmer le mandat et initier le débit ${price}€`}
        </span>
      </button>
    </form>)
}

export default CheckoutForm
