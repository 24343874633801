import imgAlphaBurn from '../../../images/pack-alphaburn.png'
import imgRecettes from '../../../images/livre-recettes.png'
import img7dc from '../../../images/7dc.png'

export const orderBump = (product, gender) => {
  const bumps = {
    packAlphaburn: {
      code: `packAlphaburn-${gender}`,
      name: "PACK DE 2 ALPHABURN",
      title: `PACK DE 2 ALPHABURN`,
      description: `LE complément pour accompagner ta perte de poids pendant ce challenge de 28 jours.\n
                 Nous l’avons crée spécialement pour accélérer tes résultats.`,
      img: imgAlphaBurn,
      price: 50,
      crossedOutPrice: 98,
      textPromo: "Seulement sur cette page",
    },
    livreRecettes: {
      code: `livreRecettes-${gender}`,
      name: "Livre de recettes",
      title: `Ebook de 44 recettes saines et gourmandes \n simples et rapides à préparer`,
      description: `Un programme sans une bonne alimentation, \n
                c’est comme un moteur avec un mauvais carburant. \n
                Facilite ta perte de poids et accélère tes résultats\n
                Sans jamais être à court d’idées.\n`,
      img: imgRecettes,
      price: 9,
      crossedOutPrice: 18,
      textPromo: "Seulement sur cette page",
    },
    "7dc": {
      code: `7dc-${gender}`,
      name: "Challenge 7 Jours",
      title: `Challenge 7 jours`,
      description: `
                Débute ta transformation \n
                pour devenir SEC et MUSCLÉ en 7 jours CHRONO \n
                Et si te remettre en forme et reprendre confiance en toi était en fait un processus agréable.`,
      img: img7dc,
      price: 7,
      textPromo: "Seulement sur cette page",
    },
    none: {
      // pour les produits sans bump
      code: ``,
      name: "",
      description: ``,
      img: "",
      price: 0,
    },
  };

  switch (product) {
    case "28dc":
      return bumps["packAlphaburn"];
    case "7dc":
      return bumps["livreRecettes"];
    case "livreRecettes":
      return bumps["7dc"];
    default:
      return bumps["none"];
  }
};
