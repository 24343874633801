import {ofType} from "redux-observable";
import {
  GET_ALL_SELLERS,
  getAllSellersSucceeded,
  POST_SALE_TO_INTEGROMAT,
  postSaleToIntegromatSucceeded,
  SAVE_THE_SALE,
  saveTheSaleSucceeded
} from "./salesActions";
import {from, map, switchMap, tap, withLatestFrom} from "rxjs";
import axios from "axios";
import {urlBdd} from "../configuration";
import {format} from "date-fns";
import {allOffers, allPaymentProvider, allPaymentType} from "../offers/products/productsWithSeller";
import {SAVE_BEFORE_ALMA_REDIRECTION} from "../payment/alma/almaEpics";
import {SAVE_BEFORE_LENBOX_REDIRECTION} from "../payment/lenbox/lenboxEpics";

export const getAllSellers$ = (actions$, state$) => actions$.pipe(
  ofType(GET_ALL_SELLERS),
  switchMap(() => from(getAllAvailableSellers())),
  map((response) => response.data.data.getSellers),
  map((allCoach) => !allCoach ? [] : allCoach.filter(coach => coach.activ)),
  map((activCoachs) => getAllSellersSucceeded(activCoachs))
)

export const saveTheSale$ = (actions$, state$) => actions$.pipe(
  ofType(SAVE_THE_SALE),
  withLatestFrom(state$),
  switchMap(([action, state]) => from(saveTheSale(action.payload, state.offerState, state.stripeState, state.almaState, state.customerState))),
  map((response) => saveTheSaleSucceeded(response))
)

export const postSaleToIntegromat$ = (actions$, state$) => actions$.pipe(
  ofType(POST_SALE_TO_INTEGROMAT),
  withLatestFrom(state$),
  switchMap(([action, state]) => from(buildIntegromatPayload(state.offerState, state.salesState, state.customerState, state.stripeState, state.almaState, state.lenboxState))),
  tap(payload => console.log('Integromat payload', payload)),
  tap((payload) => from(postSaleToIntegromat(payload))),
  map((payload) => postSaleToIntegromatSucceeded(payload))
)

const getAllAvailableSellers = () => {
  return axios({
    method: 'post',
    url: `${urlBdd}/graphQl`,
    data: {
      query: `
            query getSellers{
              getSellers{
                id,
                firstname,
                lastname,
                email,
                tel,
                activ,
                start_date,
                end_date
              }
            }`,
    },
  });
}

const isStripePayment = (customerEntries, formule) => !customerEntries.facility || customerEntries.facility === '1 fois'
  || customerEntries.paymentType === allPaymentType.SEPA
  || formule.facilityPaymentProvider === allPaymentProvider.ALPHACREDIT;

const saveTheSale = (customerId, {product, formule}, stripe, alma, customerEntries) => {
  const type = allOffers[product] ? formule.commitment.toLowerCase() : 'oneTime'; // TODO rendre ça plus explicite sur les ventes auto

  // Override de plusieurs champs si suite à une redirection depuis ALMA
  const backup = localStorage.getItem(SAVE_BEFORE_ALMA_REDIRECTION);
  const {customerState, offerState} = backup ? JSON.parse(backup) : {};
  if (customerState && offerState) {
    const isAlmaPayment = customerState.facility !== '1 fois';
    if (isAlmaPayment) {
      customerEntries = {...customerState};
    }
  }

  const variables = isStripePayment(customerEntries, formule) ?
    buildVariableFromStripe(type, product, customerId, stripe, customerEntries) :
    buildVariableFromAlma(type, customerId, alma);

  return axios({
    method: 'post',
    url: `${urlBdd}/graphQl`,
    data: {
      query: `
        mutation insertSale($type: String!, $processor: String, $processor_cus_id: String, $amount_up_front: Int!, $amount_total: Int, $next_term: String, $number_of_payments: Int, $detail: JSON, $current_product_start_date: String, $customer_id: Int!){
            insertSale(type: $type, processor: $processor, processor_cus_id: $processor_cus_id, amount_up_front: $amount_up_front, amount_total: $amount_total, next_term: $next_term, number_of_payments: $number_of_payments, detail: $detail, current_product_start_date: $current_product_start_date, customer_id: $customer_id){
                id
            }
        }`,
      variables,
    },
  })
}

const buildVariableFromStripe = (type, product, customerId, stripe, {formule, total}) => {
  return {
    type: type,
    processor: 'stripe',
    processor_cus_id: stripe.customer,
    amount_up_front: formule ? formule.price : total.finalAmount,
    amount_total: 0,
    next_term: new Date(),
    number_of_payments: 0,
    detail: `{"subscription_id": "${stripe.paymentIntent.id}"}`,
    current_product_start_date: new Date(),
    customer_id: parseInt(customerId, 10),
  }
}

const buildVariableFromAlma = (type, customerId, alma) => ({
  type: type,
  processor: 'alma',
  processor_cus_id: alma.paymentInfos.customer,
  amount_up_front: alma.paymentInfos.purchase_amount / 100,
  amount_total: 0,
  next_term: new Date(),
  number_of_payments: parseInt(alma.paymentInfos.orders[0].data.payment.charAt(0)),
  detail: `{"payment_id": "${alma.paymentInfos.id}"}`,
  current_product_start_date: new Date(),
  customer_id: parseInt(customerId, 10),
})

const buildIntegromatPayload = async ({renewal, upsell, product, formule}, {coachs}, customerEntries, stripe, alma, lenbox) => {
  // Override de plusieurs champs et update du coach state si suite à une redirection depuis ALMA
  const backup = localStorage.getItem(SAVE_BEFORE_ALMA_REDIRECTION) ?? localStorage.getItem(SAVE_BEFORE_LENBOX_REDIRECTION);
  const {customerState, offerState} = backup ? JSON.parse(backup) : {};
  if (customerState && offerState) {
    const isAlmaPayment = customerState.facility !== '1 fois';

    if (isAlmaPayment) {
      if (!coachs.length) {
        const availableSellers = await getAllAvailableSellers();
        coachs = availableSellers.data.data.getSellers;
      }
      if (offerState) {
        renewal = offerState.renewal;
        product = offerState.product;
        formule = offerState.formule;
      }
      if (customerState) {
        customerEntries = {...customerState};
      }
    }
  }

  let seller = coachs.filter(c => c.id === customerEntries.sellerId)[0];
  let paymentFacilityInfo = alma.paymentInfos ?? lenbox.paymentInfos;
  const customerId = isStripePayment(customerEntries, formule) ? stripe.customer : paymentFacilityInfo.customer;
  let formuleCode;
  let productName;
  let montant;

  // TODO Faire une fonction plus explicite / Produit oneTime ou frontend
  if (allOffers[product]) {
    formuleCode = `${formule.code}${isStripePayment(customerEntries, formule) ? '' : '_A'}`; // TODO ne fonctionnera pas avec la formation avec complément
    productName = `${allOffers[product].name} ${formule.commitment} ${customerEntries.gender}`;
    if (product.includes('Formation')) {
      productName = productName.replace(`${customerEntries.formule.commitment} `, '');
    }
    montant = formule.price * 100;
  } else {
    // TODO Fixme c'est une vente auto
    formuleCode = `${product.code}-${customerEntries.gender}`;
    productName = `${product.name} ${customerEntries.gender}`;
    montant = customerEntries.total.finalAmount * 100;
    seller = {firstname: 'Vente', lastname: 'Auto'};
  }

  return {
    nom: customerEntries.lastname,
    prenom: customerEntries.firstname,
    email: customerEntries.email,
    facebook: customerEntries.facebook ? customerEntries.facebook : "N/A",
    coach: `${seller.firstname} ${seller.lastname}`,
    tel: customerEntries.phone,
    adresse: customerEntries.address,
    zip: customerEntries.zip,
    ville: customerEntries.city,
    pays: customerEntries.country,
    montant: montant,
    produit: productName,
    formule: formuleCode,
    created_at: new Date(),
    cus_id: customerId,
    // INFO: On part du postula que uniquement les ventes front c'est pour les nouveaux clients
    newCustomer: !(renewal || upsell),
    complement: customerEntries.complement,
    productBump: customerEntries.bumpCheck ? customerEntries.productBump.code : undefined,
    insertOrUpdateCustomer: renewal || upsell ? "update" : "insert",
    giftsActiv: false // TODO checker quand s'active les gifts
  }
}

const postSaleToIntegromat = (payload) => {
  return axios({
    method: 'POST',
    url: process.env.NODE_ENV === 'development' ?
      'https://hook.integromat.com/q20rxe9725v27jdipl5utd3q2dv3e0r5' : 'https://hook.integromat.com/5irdhujarfxfz6ehpkg29cv4uekdlqvq',
    data: payload,
  });
}
