import {
  CHECK_ALMA_ELIGIBILITY_SUCCEEDED,
  CREATE_ALMA_PAYMENT,
  GET_ALMA_PAYMENT_INFOS_SUCCEEDED,
  RESET_ALMA_ELIGIBILITY, SET_ALMA_ERROR
} from "./almaActions";
import {RESET_PAYMENT} from "../paymentActions";

function resetEligibility(state) {
  delete state.eligibility;
  state.paymentProcessing = false;
  return state;
}

export const almaReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case CHECK_ALMA_ELIGIBILITY_SUCCEEDED:
      return {
        ...state,
        eligibility: action.payload
      }
    case GET_ALMA_PAYMENT_INFOS_SUCCEEDED:
      return {
        ...state,
        paymentInfos: action.payload,
      }
    case CREATE_ALMA_PAYMENT:
      return {
        ...state,
        paymentProcessing: true,
      }
    case SET_ALMA_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_ALMA_ELIGIBILITY:
      return resetEligibility(state);
    case RESET_PAYMENT:
      return {}
    default:
      return state
  }
}