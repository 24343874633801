import React from 'react';
import store from "./store/store";

import VipCoachingHome from './pages/Accueil';
import RenewalHome, {
  encodedUriToProductFormulaWithAlphabodyCreditMapping,
  encodedUriToRenewalFormulaWithAlmaMapping,
  encodedUriToRenewalFormulaWithLenboxMapping,
  encodedUriToRenewalFormulaWithStripeOnlyMapping,
  renewalFormationFormulaMapping,
  renewalVIPFormulaMapping
} from './pages/AccueilRenew';
import Checkout from './pages/Checkout';
import CheckoutAuto from "./pages/Checkout-auto";
import FinishAlma from './pages/finishAlma';
import Success from './pages/Success';

import './App.css';

import {Navigate, Route, Routes, useLocation, useParams, useSearchParams} from 'react-router-dom';
import {productsBuyableAuto} from "./store/offers/products/productsBuyableAuto";
import {useDispatch} from "react-redux";
import {selectedFormule, selectedProduct, selectedProductAuto} from "./store/offers/offersActions";
import {SAVE_BEFORE_ALMA_REDIRECTION} from "./store/payment/alma/almaEpics";
import {SAVE_BEFORE_LENBOX_REDIRECTION} from "./store/payment/lenbox/lenboxEpics";
import {sellableProducts} from "./store/offers/products/productsWithSeller";
import {getCustomerInformation} from "./store/customer/customerActions";
import AllUrls, {
  encodedUriToProductFormuleWithAlmaMapping,
  encodedUriToProductFormuleWithAlphabodyCreditMapping,
  encodedUriToProductFormulaWithLenboxMapping,
  productFormuleMapping
} from "./pages/AllVipUrls";
import AllSpecialOffersUrls, {
  encodedUriToSpecialOfferFormuleMapping,
  upsellFormuleMapping
} from "./pages/AllSpecialOffersUrls";
import AllFormationUrls from "./pages/AllFormationUrls";
import FinishLenbox from "./pages/finishLenbox";

function App() {
  const dispatch = useDispatch();
  const {pathname, search} = useLocation();

  const cleanLocalStorage = () => {
    localStorage.removeItem(SAVE_BEFORE_ALMA_REDIRECTION);
    localStorage.removeItem(SAVE_BEFORE_LENBOX_REDIRECTION);
  }

  const LoadVipProductGuard = ({children}) => {
    cleanLocalStorage();
    const params = useParams();
    const encodeFormule = params['encodedFormule'];
    const product = pathname.includes('vip-homme') ? sellableProducts.VIP_HOMME : sellableProducts.VIP_FEMME;
    const offerState = store.getState()['offerState'];
    const preSelectedProduct = offerState.product;
    const preSelectedFormule = offerState.formule;

    if (encodeFormule && encodedUriToProductFormuleWithAlmaMapping[encodeFormule]) {
      if (!preSelectedProduct) {
        dispatch(selectedProduct(product));
      }
      if (!preSelectedFormule) {
        const commitment = encodedUriToProductFormuleWithAlmaMapping[encodeFormule];
        dispatch(selectedFormule(productFormuleMapping(product)[commitment]
          .formules.find(formule => formule.encoded === encodeFormule).formule));
      }
      return children;
    }

    if (encodeFormule && encodedUriToProductFormulaWithLenboxMapping[encodeFormule]) {
      if (!preSelectedProduct) {
        dispatch(selectedProduct(product));
      }
      if (!preSelectedFormule) {
        const commitment = encodedUriToProductFormulaWithLenboxMapping[encodeFormule];
        dispatch(selectedFormule(productFormuleMapping(product)[commitment]
          .formules.find(formule => formule.encoded === encodeFormule).formule));
      }
      return children;
    }

    if (encodeFormule && encodedUriToProductFormuleWithAlphabodyCreditMapping[encodeFormule]) {
      if (!preSelectedProduct) {
        dispatch(selectedProduct(product));
      }
      if (!preSelectedFormule) {
        const commitment = encodedUriToProductFormuleWithAlphabodyCreditMapping[encodeFormule];
        dispatch(selectedFormule(productFormuleMapping(product)[commitment]
          .formules.find(formule => formule.encoded === encodeFormule).formule));
      }
      return children;
    }

    return <Navigate to={'/'}/>;
  }

  const LoadSpecialOfferProductGuard = ({children}) => {
    cleanLocalStorage();
    const params = useParams();
    const [searchParams] = useSearchParams();
    dispatch(getCustomerInformation(searchParams.get('email')));

    if (params.encodedFormule && encodedUriToSpecialOfferFormuleMapping[params.encodedFormule]) {
      const product = sellableProducts.UPSELL_VIP_HOMME;
      dispatch(selectedProduct(product));
      const commitment = encodedUriToSpecialOfferFormuleMapping[params.encodedFormule];
      dispatch(selectedFormule(upsellFormuleMapping(product)[commitment].formule));
      return children;
    }
    return <Navigate to={'/'}/>;
  }

  const LoadRenewalVipProductGuard = ({children}) => {
    cleanLocalStorage();
    const params = useParams();
    const [searchParams] = useSearchParams();
    dispatch(getCustomerInformation(searchParams.get('email')));
    const product = pathname.includes('vip-homme') ? sellableProducts.RENEWAL_VIP_HOMME : sellableProducts.RENEWAL_VIP_FEMME;
    const encodeFormule = params['encodedFormule'];
    const offerState = store.getState()['offerState'];
    const preSelectedProduct = offerState.product;
    const preSelectedFormula = offerState.formule;

    const fragment = [encodedUriToRenewalFormulaWithStripeOnlyMapping, encodedUriToRenewalFormulaWithAlmaMapping,
      encodedUriToRenewalFormulaWithLenboxMapping, encodedUriToProductFormulaWithAlphabodyCreditMapping].map(fn => {
      if (encodeFormule && fn[params.encodedFormule]) {
        if (!preSelectedProduct) {
          dispatch(selectedProduct(product));
        }
        if (!preSelectedFormula) {
          const commitment = fn[params.encodedFormule];
          dispatch(selectedFormule(renewalVIPFormulaMapping(product)[commitment]
            .formules.find(formula => formula.encoded === encodeFormule).formule));
        }
        return children;
      }
    });
    return fragment ? fragment : <Navigate to={'/'}/>;
  }

  const LoadRenewalFormProductGuard = ({children}) => {
    cleanLocalStorage();
    const [searchParams] = useSearchParams();
    dispatch(getCustomerInformation(searchParams.get('email')));
    const product = pathname.includes('form-homme') ? sellableProducts.RENEWAL_FORMATION_HOMME : sellableProducts.RENEWAL_FORMATION_FEMME;
    dispatch(selectedProduct(product));
    dispatch(selectedFormule(renewalFormationFormulaMapping(product)['Mensuel'].formules[0].formule));
    return children;
  }

  const LoadProductAutoGuard = ({children}) => {
    cleanLocalStorage();
    const params = useParams();
    if (params.product) {
      dispatch(selectedProductAuto(productsBuyableAuto[params.product]));
      return children;
    }
    return <Navigate to={'/'}/>;
  }

  const LoadFormationGuard = ({children}) => {
    cleanLocalStorage();
    const product = pathname.includes('form-homme') ? sellableProducts.FORMATION_HOMME : sellableProducts.FORMATION_FEMME;
    dispatch(selectedProduct(product));
    return children;
  }

  return (
    <Routes>
      {/* TOUTES LES AUTRES ROUTES ENVOIES VERS L ACCUEIL */}
      <Route path="*" element={<Navigate to={`/${search}`} replace={true}/>}/>

      {/*Route upfront*/}
      <Route path='/' element={<VipCoachingHome/>}/>
      <Route path='/vip/all-urls' element={<AllUrls/>}/>
      <Route path='/vip-homme/:encodedFormule' element={<LoadVipProductGuard><Checkout gender={'homme'} isVip={true}/></LoadVipProductGuard>}/>
      <Route path='/vip-femme/:encodedFormule' element={<LoadVipProductGuard><Checkout gender={'femme'} isVip={true}/></LoadVipProductGuard>}/>

      {/*Route upfront*/}
      <Route path='/special-offers/all-urls' element={<AllSpecialOffersUrls/>}/>
      <Route path='/special-offers/:encodedFormule' element={<LoadSpecialOfferProductGuard><Checkout gender={'homme'} isVip={true}/></LoadSpecialOfferProductGuard>}/>

      {/*Route renewals*/}
      <Route path='/renewal' element={<RenewalHome/>}/>
      <Route path='/renew-vip-homme/:encodedFormule' element={<LoadRenewalVipProductGuard><Checkout gender={'homme'} isVip={true}/></LoadRenewalVipProductGuard>}/>
      <Route path='/renew-vip-femme/:encodedFormule' element={<LoadRenewalVipProductGuard><Checkout gender={'femme'} isVip={true}/></LoadRenewalVipProductGuard>}/>
      <Route path='/renew-form-homme' element={<LoadRenewalFormProductGuard><Checkout gender={'homme'} isSales={true} isVip={false}/></LoadRenewalFormProductGuard>}/>
      <Route path='/renew-form-femme' element={<LoadRenewalFormProductGuard><Checkout gender={'femme'} isSales={true} isVip={false}/></LoadRenewalFormProductGuard>}/>

      {/*Route checkout automatique*/}
      <Route path='/product/:product' element={<LoadProductAutoGuard><CheckoutAuto/></LoadProductAutoGuard>}/>

      {/*Routes finish sale*/}
      <Route path='/finishAlma' element={<FinishAlma/>}/>
      <Route path='/finishLenbox' element={<FinishLenbox/>}/>
      <Route path='/success' element={<Success/>}/>

      {/*
      DESACTIVATION DE LA PAGE DE VENTE PROMO
      <Route path='/page-promo' element={<SalesPage />} />
      */}

      {/*Route formations*/}
      <Route path='/formation/all-urls' element={<AllFormationUrls/>}/>
      <Route path='/form-homme' element={<LoadFormationGuard><Checkout gender={'homme'} isVip={false}/></LoadFormationGuard>}/>
      <Route path='/form-femme' element={<LoadFormationGuard><Checkout gender={'femme'} isVip={false}/></LoadFormationGuard>}/>

    </Routes>
  );
}

export default App;
