import React, {useEffect} from 'react';
import {Controller} from 'react-hook-form';
import TextField from "@material-ui/core/TextField";
import {useSelector} from "react-redux";
import countryList from "./country.json";
import Select from "react-select";
import {Alert} from "@material-ui/lab";

const CustomerAddress = ({form}) => {
  const {register, errors, control, setValue} = form;
  const customerInformation = useSelector((state) => state.customerState);
  const {renewal, upsell} = useSelector((state) => state.offerState);

  useEffect(() => {
    if (renewal || upsell) {
      setValue('address', customerInformation.address);
      setValue('zip', customerInformation.zip);
      setValue('city', customerInformation.city);
    }
  }, [renewal, upsell, customerInformation]);

  return (
    <div className='inputs' id='zone'>

      <TextField id='address' label='Adresse postale *' variant='outlined'
                 {...register("address", {required: true})} />
      {errors.address?.type === 'required' && <Alert severity='error'>Merci de renseigner ce champ</Alert>}

      <TextField id='zip' label='Code postal *' variant='outlined'
                 {...register("zip", {required: true})} />
      {errors.zip?.type === 'required' && <Alert severity='error'>Merci de renseigner ce champ</Alert>}

      <TextField id='city' label='Ville *' variant='outlined'
                 {...register("city", {required: true})} />
      {errors.city?.type === 'required' && <Alert severity='error'>Merci de renseigner ce champ</Alert>}

      <Controller control={control} name="country" rules={{required: true}} defaultValue={"FR"}
                  render={({field: {onChange, value, ref}}) => (
                    <Select inputRef={ref} className='react-select' defaultValue={{label: 'France', value: 'FR'}}
                            classNamePrefix='react-select' options={countryList}
                            onChange={(val) => onChange(val.value)} />
                  )}
      />
      {errors.country?.type === 'required' && <Alert severity='error'>Merci de renseigner ce champ</Alert>}

    </div>
  )
}

export default CustomerAddress;