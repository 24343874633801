import React, {useEffect} from 'react';
import './success.css';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import imgRecettes from '../../images/livre-recettes.png'
import logo from '../../images/Logo-Alphabody-White-100-300.png'
import {resetPayment} from "../../store/payment/paymentActions";
import {allOffers} from "../../store/offers/products/productsWithSeller";

const Success = () => {
  const dispatch = useDispatch();
  const {product, productBump, bumpCheck} = useSelector((state) => state.offerState);
  const {gender} = useSelector((state) => state.customerState);

  // sleep time expects milliseconds
  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  //navigation vers un autre produit
  const navigate = useNavigate()
  const goOtherProduct = (url) => {
    dispatch(resetPayment());
    navigate(url)
  }
  useEffect(() => {
    if (product && product.code === `resa-immersion`) {
      sleep(500).then(() => {
        goOtherProduct("/product/immersion")
      });
    }
  }, [product])

  return (
    <div className="success">
      <div className="container">
        <div className="bandeau">Récapitulatif de ta commande</div>

        {/* AFFICHAGE DU NOM DE PRODUIT */
          product ?
            <div className="commande text">- {allOffers[product] ? allOffers[product].name : product.name}</div>
            :
            <div className="commande text">- Formation Alphabody</div>
        }

        {/* SI PRODUCT BUMP */
          bumpCheck &&
          <div className="commande text">- {productBump.name}</div>
        }

        <div className="bandeau">Merci et félicitation</div>
        <div className="text">
          Tu vas recevoir, très bientôt, un mail de notre part avec toutes les informations dont tu as besoin.
        </div>

        { /*MASQUAGE SI PORDUIT SANS ESPACE MEMBRE TODO Ajouter l'espace membre dans la description du produit directement */
          product && (!!allOffers[product] || !(product.code && product.code.includes(['resa', 'conf', 'immersion']))) &&
          <>
            <div className="bandeau">Connecte toi à ton espace membre :</div>
            <div className="text">
              <a href="https://formations.alphabody.fr/"> formation.alphabody.fr</a>
            </div>
          </>
        }

        {/* TELECHARGEMENT DU LIVRE DE RECETTE */
          (product === `livreRecettes` || (productBump && productBump.code === `livreRecettes-${gender}` && bumpCheck)) &&
          <>
            <div className="bandeau">Télecharge ton livre de recettes :</div>
            <div className="text">
              <a href={imgRecettes} target="_blank" rel="noopener noreferrer">
                livre de recettes
              </a>
            </div>
          </>
        }

        {/* paiement totalité immersion */
          product && (product.code === `resa-immersion`) &&
          <>
            <div className="bandeau">Complète le paiement pour l'immersion :</div>
            <div className="text">
              Tu vas être redirigé vers le paiement de l'immersion Alphabody d'ici quelques secondes
            </div>
            <div className="text">
              <button
                onClick={() => goOtherProduct("/product/immersion")}
              >
                <div className='spinner' id='spinner'></div>
                Paiement Immersion
              </button>
            </div>
          </>
        }

        <div className="bandeau"></div>
        <div className="text">
          Si tu rencontres des difficultés, contact-nous directement à contact@alphabody.fr
        </div>

        <div className="ctn-footer"><img src={logo} alt="logo"/></div>
      </div>
    </div>
  )
}

export default Success;
