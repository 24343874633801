import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import React, {useState} from 'react';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {allComplements} from "../../../store/offers/products/complements";

const styles = {
  media: {
    width: '50%',
    margin: '0 auto',
  },
  card: {
    'margin-bottom': '15px',
  },
  cardElm: {
    padding: '5px',
  },
  desc: {
    fontWeight: 'bold',
    margin: '2px',
  },
};

const Complements = ({form}) => {
  const {register} = form;
  const [productSelected, setProductSelected] = useState(allComplements[0]);

  return (
    <Card style={styles.card}>
      <CardContent style={styles.cardElm}>
        <FormLabel component='legend'>Choix du complément</FormLabel>
        <Select labelId='complements' id='simple-select' value={productSelected.code}
                {...register("complement", { required: true })}
                onChange={(e) =>
                  setProductSelected(allComplements.find(c => c.code === e.target.value))}>
          {allComplements.map((elm) => {
            return (
              <MenuItem key={elm.code} value={elm.code}>
                {elm.name} <br />
                {elm.saveur && `${elm.saveur}`}
              </MenuItem>
            );
          })}
        </Select>
        <Typography style={styles.desc} variant='subtitle1' color='text.secondary' component='div'>
          {productSelected.description}
        </Typography>
        <CardMedia component='img' image={productSelected.img} alt={productSelected.code} style={styles.media}/>
      </CardContent>
    </Card>
  );
};

export default Complements;