import {
  CHECK_ALMA_ELIGIBILITY,
  checkAlmaEligibilitySucceeded,
  CREATE_ALMA_PAYMENT,
  CREATE_ALMA_PAYMENT_SUCCEEDED,
  createAlmaPaymentSucceeded,
  GET_ALMA_PAYMENT_INFOS,
  getAlmaPaymentInfosSucceeded
} from "./almaActions";
import axios from "axios";
import {catchError, from, ignoreElements, map, mergeMap, switchMap, tap, withLatestFrom} from "rxjs";
import {urlBdd} from "../../configuration";
import {ofType} from "redux-observable";
import {fulfillCustomerInformation} from "../../customer/customerActions";
import {selectedFormule, selectedProduct} from "../../offers/offersActions";
import {allOffers} from "../../offers/products/productsWithSeller";
import {productsBuyableAuto} from "../../offers/products/productsBuyableAuto";

export const SAVE_BEFORE_ALMA_REDIRECTION = "saveBeforeAlmaRedirection";

export const checkAlmaEligibility$ = (actions$, state$) => actions$.pipe(
  ofType(CHECK_ALMA_ELIGIBILITY),
  withLatestFrom(state$),
  switchMap(([action, state]) => from(requestAlmaEligibility(state.offerState, action.payload))),
  map((result) => !!result.data[0] ? checkAlmaEligibilitySucceeded(result.data[0]) : checkAlmaEligibilitySucceeded({eligible: false})),
  catchError(() => checkAlmaEligibilitySucceeded({eligible: false}))
);
export const createAlmaPayment$ = (actions$, state$) => actions$.pipe(
  ofType(CREATE_ALMA_PAYMENT),
  withLatestFrom(state$),
  switchMap(([action, state]) => from(requestAlmaPayment(state.offerState, action.payload))),
  map((result) => createAlmaPaymentSucceeded(result.data)),
);

export const saleRedirectionAfterAlmaCreatePayment$ = (action$, state$) => action$.pipe(
  ofType(CREATE_ALMA_PAYMENT_SUCCEEDED),
  withLatestFrom(state$),
  tap(([action, state]) => {
    localStorage.setItem(SAVE_BEFORE_ALMA_REDIRECTION, JSON.stringify({
      customerState: state.customerState,
      offerState: state.offerState
    }));
    window.location.replace(action.payload.url);
  }),
  ignoreElements()
);

export const getAlmaInformation$ = (actions$, state$) => actions$.pipe(
  ofType(GET_ALMA_PAYMENT_INFOS),
  switchMap((action) => from(requestAlmaInformation(action.payload))),
  mergeMap((result) => [
    fulfillCustomerInformation(buildCustomerFrom(result.data)),
    selectedProduct(extractProductFrom(result.data)),
    selectedFormule(extractFormuleFrom(result.data)),
    getAlmaPaymentInfosSucceeded(extractPertinentAlmaPaymentInfos(result))]
  )
);

const extractPertinentAlmaPaymentInfos = (result) => ({
  id: result.data.id,
  state: result.data.state,
  orders: result.data.orders,
  customer: result.data.customer.id,
  purchase_amount: result.data.purchase_amount
})

const buildCustomerFrom = (almaPaymentInfos) => {
  return {
    gender: extractGenderFrom(almaPaymentInfos),
    nom: almaPaymentInfos.customer.last_name,
    prenom: almaPaymentInfos.customer.first_name,
    telephone: almaPaymentInfos.customer.phone,
    email: almaPaymentInfos.customer.email,
    address: almaPaymentInfos.billing_address.line1,
    zip: almaPaymentInfos.billing_address.postal_code,
    city: almaPaymentInfos.billing_address.city,
    country: almaPaymentInfos.billing_address.country,
    facebook: almaPaymentInfos.orders[0].data.facebook,
  }
}

// TODO besoin de mettre de l'encapsulation ou de déporter la logique dans le backend
const extractProductFrom = (almaPaymentInfos) => {
  const oneTimeProduct = almaPaymentInfos.orders[0].data.product.code;
  if (!!productsBuyableAuto[oneTimeProduct]) {
    return productsBuyableAuto[oneTimeProduct];
  } else {
    const product = almaPaymentInfos.orders[0].data.product.name;
    const removeFormuleFrom = (product) => product.replace(/Mensuel|Trimestriel|Semestriel|Annuel|OneMoreYear|SixAnotherMonths/gi, matched => "").slice(0, -1);
    return removeFormuleFrom(product);
  }
}

const extractFormuleFrom = (almaPaymentInfos) => {
  const product = allOffers[extractProductFrom(almaPaymentInfos)];
  return product ?
    product.formules.find(f => f.code === almaPaymentInfos.orders[0].data.formule.code) :
    almaPaymentInfos.orders[0].data.formule;
}

const extractGenderFrom = (almaPaymentInfos) => {
  return almaPaymentInfos.orders[0].data.formule.code.includes(['H', 'h']) ? 'homme' : 'femme';
}

const requestAlmaEligibility = ({product, formule}, data) => {
  const price = allOffers[product] ? formule.price : product.price; // TODO rendre plus explicite les produit auto ou frontend
  return axios
    .post(`${urlBdd}/alma/eligibility`, {
      data: {
        firstname: data.firstname,
        lastname: data.lastname,
        adress1: data.address,
        postal_code: data.zip,
        city: data.city,
        country: data.country,
        email: data.email,
        phone: data.phone,
        amount: Math.round(price * 100),
        installments_count: parseInt(data.facility.split(" ")[0]),
      },
    })
}

const requestAlmaPayment = ({product}, data) => {
  const productProjectionLite = {...allOffers[product]};
  delete productProjectionLite.formules;

  // TODO refactoré product frontend et auto
  let price;
  let formattedProduct;
  if (allOffers[product]) {
    price = data.formule.price;
    // TODO distinction homme femme
    const productName = product.includes('-') ? `${product}-${data.formule.commitment}` : `${product}_${data.formule.commitment}`;
    formattedProduct = {name: productName, code: productName};
  } else {
    price = product.price;
    formattedProduct = product;
  }

  return axios
    .post(`${urlBdd}/alma/payments`, {
      data: {
        requestDate: new Date(),
        firstname: data.firstname,
        lastname: data.lastname,
        adress1: data.address,
        postal_code: data.zip,
        city: data.city,
        country: data.country,
        email: data.email,
        phone: data.phone,
        amount: Math.round(price * 100),
        installments_count: parseInt(data.facility.split(" ")[0]),
        //infos utiles lors du retour apres paiement
        facebook: data.facebook,
        coach: data.coachSeller,
        formule: data.formule,
        gender: data.gender,
        back_url: window.location.pathname,
        payment: data.facility,
        newCustomer: false,
        complement: data.complement,
        giftsActiv: data.giftsActiv,
        product: formattedProduct,
        fullProduct: productProjectionLite,
        customer_cancel_url: `${window.location.origin}/finishAlma`,
        return_url: `${window.location.origin}/finishAlma`,
      },
    })
}

const requestAlmaInformation = (almaPaymentId) => {
  return axios.get(`${urlBdd}/alma/payments?payment_id=${almaPaymentId}`)
}
