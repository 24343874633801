import {styles, useStyles} from "./styles";
import React, {useState} from "react";
import {Checkbox, FormControlLabel} from "@material-ui/core";

export const CardBump = ({productBump, handleBumpCheck}) => {
  const classes = useStyles();
  const [bumpCheck, setBumpCheck] = useState(false);

  const bumpCheckChanged = (checked) => {
    setBumpCheck(checked);
    handleBumpCheck(checked);
  }
  return (
    <div className="cardBump">
      <h1>
        {productBump.title.split("\n").map((el, key) => (
          <div key={key}>{el}</div>
        ))}
      </h1>
      <div className="content">
        <div className="imageContainer">
          <img
            src={productBump.img}
            alt={`image ${productBump.code} `}
          />
        </div>
        <div className="textContainer">
          <p className="bumpPrice">{productBump.price} EUROS</p>
          {productBump.crossedOutPrice && (
            <p className="crossedOutPrice">
              au lieu de {productBump.crossedOutPrice} EUROS
            </p>
          )}
          {productBump.textPromo && (
            <p className="textPromo">{productBump.textPromo}</p>
          )}
          <p>
            <FormControlLabel
              className={classes.width}
              control={<Checkbox color="primary"/>}
              label="Oui, je le prends!"
              style={styles.formCheckBox}
              value={bumpCheck}
              onChange={(e) => bumpCheckChanged(e.target.checked)}
            />
          </p>
        </div>
      </div>
      <p className="bumpDescription">
        {productBump.description.split("\n").map((el, key) => (
          <span key={key}>{el}</span>
        ))}
      </p>
    </div>
  )
}