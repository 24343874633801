import {ofType} from "redux-observable";
import {catchError, from, map, mergeMap, switchMap, tap, withLatestFrom} from "rxjs";
import {
    CREATE_LENBOX_PAYMENT, GET_LENBOX_PAYMENT_INFOS, getLenboxPaymentInfosSucceeded,
    REDIRECT_TO_LENBOX_PAYMENT_PAGE,
    redirectToLenboxPaymentPage,
    updateLenboxError
} from "./lenboxActions";
import axios from "axios";
import {allOffers} from "../../offers/products/productsWithSeller";
import {urlBdd} from "../../configuration";
import {fulfillCustomerInformation} from "../../customer/customerActions";
import {selectedFormule, selectedProduct} from "../../offers/offersActions";

export const SAVE_BEFORE_LENBOX_REDIRECTION = "saveBeforeLenboxRedirection"

const LENBOX_ERROR_STATUS = 'NOT_RUN';
const ERROR_MESSAGE = "An error occured. Please try again later.";

export const createLenboxPayment$ = (actions$, state$) => actions$.pipe(
    ofType(CREATE_LENBOX_PAYMENT),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
        from(requestLenboxPaymentUrl(state.offerState, action.payload))
    ),
    map((result) => {
            if (result.data.data.status !== LENBOX_ERROR_STATUS) {
                return redirectToLenboxPaymentPage(result)
            }
            return updateLenboxError(result.data.data.message)
        }
    ),
    catchError(() => updateLenboxError(ERROR_MESSAGE))
)

export const redirectToLenboxPaymentPage$ = (actions$, state$) => actions$.pipe(
    ofType(REDIRECT_TO_LENBOX_PAYMENT_PAGE),
    withLatestFrom(state$),
    tap(([action, state]) => {
        localStorage.setItem(SAVE_BEFORE_LENBOX_REDIRECTION, JSON.stringify({
            customerState: state.customerState,
            offerState: state.offerState
        }));
        window.location.replace(action.payload.data.data.response.url);
    }),
)
const requestLenboxPaymentUrl = ({product}, data) => {
    const productProjectionLite = {...allOffers[product]};
    delete productProjectionLite.formules;

    // TODO refactoré product frontend et auto
    let price;
    let formattedProduct;
    if (allOffers[product]) {
        price = data.formule.price;
        // TODO distinction homme femme
        const productName = product.includes('-') ? `${product}-${data.formule.commitment}` : `${product}_${data.formule.commitment}`;
        formattedProduct = {name: productName, code: productName};
    } else {
        price = product.price;
        formattedProduct = product;
    }

    return axios
        .post(`${urlBdd}/lenbox/payments`, {
            data: {
                requestDate: new Date(),
                firstname: data.firstname,
                lastname: data.lastname,
                adress1: data.address,
                postal_code: data.zip,
                city: data.city,
                country: data.country,
                email: data.email,
                phone: data.phone,
                amount: price,
                installments_count: parseInt(data.facility.split(" ")[0]),
                facebook: data.facebook,
                coach: data.coachSeller,
                formule: data.formule,
                gender: data.gender,
                back_url: window.location.pathname,
                payment: data.facility,
                newCustomer: false,
                complement: data.complement,
                giftsActiv: data.giftsActiv,
                product: formattedProduct,
                fullProduct: productProjectionLite,
                customer_cancel_url: `${window.location.origin}/finishLenbox`,
                return_url: `${window.location.origin}/finishLenbox`,
            },
        })
}

export const getLenboxInformation$ = (actions$, state$) => actions$.pipe(
    ofType(GET_LENBOX_PAYMENT_INFOS),
    switchMap((action) => from(requestLenboxInformation(action.payload))),
    mergeMap((result) => [
        fulfillCustomerInformation(result.data.customerInformation),
        selectedProduct(result.data.product),
        selectedFormule(result.data.formule),
        getLenboxPaymentInfosSucceeded(result.data.paymentInformation)]
    )
)

const requestLenboxInformation = (lenboxPaymentId) =>
    axios.get(`${urlBdd}/lenbox/payments?payment_id=${lenboxPaymentId}`)
