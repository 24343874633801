import {GET_ALL_SELLERS_SUCCEEDED} from "./salesActions";

const defaultSalesState = {
  coachs: []
}

export const salesReducer = (state = defaultSalesState, action = {}) => {
  switch (action.type) {
    case GET_ALL_SELLERS_SUCCEEDED:
      return {
        ...state,
        coachs: action.payload,
      }
    default:
      return state;
  }
}