import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import moment from "moment";
import {Alert} from "@mui/lab";
import {allOffers} from "../../../../../store/offers/products/productsWithSeller";

const AlmaEligibility = () => {
  const eligibility = useSelector((state) => state.almaState.eligibility);
  const {product, formule} = useSelector((state) => state.offerState);

  useEffect(() => {
  }, [eligibility]);

  const cents_to_price = (cents) => {
    return parseInt(cents) / 100;
  };

  const convertDueDate = (date) => {
    return moment.unix(date).locale('FR').format('DD MMMM YYYY', 'fr');
  };

  const getProductPrice = () => allOffers[product] ? formule.price : product.price;

  return (
    <>
      {eligibility &&
          <Alert severity={eligibility.eligible ? 'success' : 'error'}>
            {eligibility.eligible
              ? `Vous êtes éligible pour ${getProductPrice()} Eur en ${eligibility.installments_count} fois`
              : "Vous n'êtes pas éligible"}
            {eligibility.eligible &&
              eligibility.payment_plan.map((plan, key) => (
                <div key={key}>
                  {cents_to_price(plan.purchase_amount)} Eur le{' '}
                  {convertDueDate(plan.due_date)}
                </div>
              ))}
          </Alert>}
    </>
  )
}

export default AlmaEligibility;