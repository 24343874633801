export const sellableProducts = {
  VIP_HOMME: 'Coaching-VIP-Alphabody',
  VIP_FEMME: 'Coaching_VIP_Alphabody',
  FORMATION_HOMME: 'Formation-Alphabody',
  FORMATION_FEMME: 'Formation_Alphabody',
  RENEWAL_VIP_HOMME: 'Coaching-VIP-Alphabody-Renouvellement',
  RENEWAL_VIP_FEMME: 'Coaching_VIP_Alphabody_Renouvellement',
  RENEWAL_FORMATION_HOMME: 'Formation-Alphabody-Renouvellement',
  RENEWAL_FORMATION_FEMME: 'Formation_Alphabody_Renouvellement',
  UPSELL_VIP_HOMME: 'Coaching-VIP-Alphabody-Upsell',
}

export const allFormule = {
  TWO_WEEKS: 'TwoWeeks',
  MENSUEL: 'Mensuel', TRIMESTRIEL: 'Trimestriel', SEMESTRIEL: 'Semestriel', ANNUEL: 'Annuel',
  ONE_MORE_YEAR: 'OneMoreYear', SIX_ANOTHER_MONTHS: 'SixAnotherMonths'
}

export const allPaymentType = {
  CARD: 'CARD', SEPA: 'SEPA', LENBOX: 'LENBOX'
}

export const allPaymentProvider = {
  ALPHACREDIT: 'ALPHACREDIT', ALMA: 'ALMA', LENBOX: 'LENBOX'
}

export const allOffers = {
  'Coaching-VIP-Alphabody': {
    id: 3,
    code: 'VH',
    name: 'Coaching VIP Alphabody',
    product: sellableProducts.VIP_HOMME,
    isSellerNeeded: true,
    defaultFormule: 9,
    formules: [
      {
        id: 0, code: 'VH2_WEEKS',
        commitment: allFormule.TWO_WEEKS, price: 150, priceLabel: '150 Eur / 2 semaines',
        methods: [allPaymentType.CARD]
      },
      {
        id: 1, code: 'VHM',
        commitment: allFormule.MENSUEL, price: 450, priceLabel: 'Mensuel 450 Eur / mois',
        crossPrice: '550 Eur / mois', discount: '-20%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA]
      },
      {
        id: 2, code: 'VHT',
        commitment: allFormule.TRIMESTRIEL, price: 1350, priceLabel: 'Trimestriel 1350 Eur / 3 mois',
        crossPrice: '1700 Eur / 3 mois', discount: '-20%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 3
      },
      {
        id: 3, code: 'VHT',
        commitment: allFormule.TRIMESTRIEL, price: 1350, priceLabel: 'Trimestriel 1350 Eur / 3 mois',
        crossPrice: '1700 Eur / 3 mois', discount: '-20%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 8
      },
      {
        id: 4, code: 'VHT',
        commitment: allFormule.TRIMESTRIEL, price: 1350, priceLabel: 'Trimestriel 1350 Eur / 3 mois',
        crossPrice: '1700 Eur / 3 mois', discount: '-20%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 10
      },
      {
        id: 6, code: 'VHS',
        commitment: allFormule.SEMESTRIEL, price: 1800, priceLabel: 'Semestriel 1800 Eur / 6 mois',
        crossPrice: '3000 Eur / 6 mois', discount: '-40%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 6
      },
      {
        id: 7, code: 'VHS',
        commitment: allFormule.SEMESTRIEL, price: 1800, priceLabel: 'Semestriel 1800 Eur / 6 mois',
        crossPrice: '3000 Eur / 6 mois', discount: '-40%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 12
      },
      {
        id: 8, code: 'VHS',
        commitment: allFormule.SEMESTRIEL, price: 1800, priceLabel: 'Semestriel 1800 Eur / 6 mois',
        crossPrice: '3000 Eur / 6 mois', discount: '-40%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 10
      },
      {
        id: 9, code: 'VHS_10X',
        commitment: allFormule.SEMESTRIEL, price: 1800, priceLabel: 'Semestriel 1800 Eur / 6 mois',
        crossPrice: '3000 Eur / 6 mois', discount: '-40%',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALPHACREDIT,
        maxFacility: 10
      },
      {
        id: 10, code: 'VHA',
        commitment: allFormule.ANNUEL, price: 3000, priceLabel: 'Annuel 3000 Eur / an',
        crossPrice: '5500 Eur / an', discount: '-45%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 12
      },
      {
        id: 11, code: 'VHA',
        commitment: allFormule.ANNUEL, price: 3000, priceLabel: 'Annuel 3000 Eur / an',
        crossPrice: '5500 Eur / an', discount: '-45%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 4
      },
      {
        id: 12, code: 'VHA_10X',
        commitment: allFormule.ANNUEL, price: 3000, priceLabel: 'Annuel 3000 Eur / an',
        crossPrice: '5500 Eur / an', discount: '-45%',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALPHACREDIT,
        maxFacility: 10
      },
    ]
  },
  'Coaching_VIP_Alphabody': {
    id: 4,
    code: 'VF',
    name: 'Coaching VIP Alphabody',
    product: sellableProducts.VIP_FEMME,
    isSellerNeeded: true,
    defaultFormule: 9,
    formules: [
      {
        id: 1, code: 'VFM',
        commitment: allFormule.MENSUEL, price: 450, priceLabel: 'Mensuel 450 Eur / mois',
        crossPrice: '550 Eur / mois', discount: '-20%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA]
      },
      {
        id: 2, code: 'VFT',
        commitment: allFormule.TRIMESTRIEL, price: 1350, priceLabel: 'Trimestriel 1350 Eur / 3 mois',
        crossPrice: '1700 Eur / 3 mois', discount: '-20%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 3
      },
      {
        id: 3, code: 'VFT',
        commitment: allFormule.TRIMESTRIEL, price: 1350, priceLabel: 'Trimestriel 1350 Eur / 3 mois',
        crossPrice: '1700 Eur / 3 mois', discount: '-20%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 8
      },
      {
        id: 4, code: 'VFT',
        commitment: allFormule.TRIMESTRIEL, price: 1350, priceLabel: 'Trimestriel 1350 Eur / 3 mois',
        crossPrice: '1700 Eur / 3 mois', discount: '-20%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 10
      },
      {
        id: 6, code: 'VFS',
        commitment: allFormule.SEMESTRIEL, price: 1800, priceLabel: 'Semestriel 1800 Eur / 6 mois',
        crossPrice: '3000 Eur / 6 mois', discount: '-40%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 6
      },
      {
        id: 7, code: 'VFS',
        commitment: allFormule.SEMESTRIEL, price: 1800, priceLabel: 'Semestriel 1800 Eur / 6 mois',
        crossPrice: '3000 Eur / 6 mois', discount: '-40%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 12
      },
      {
        id: 8, code: 'VFS',
        commitment: allFormule.SEMESTRIEL, price: 1800, priceLabel: 'Semestriel 1800 Eur / 6 mois',
        crossPrice: '3000 Eur / 6 mois', discount: '-40%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 10
      },
      {
        id: 9, code: 'VFS_10X',
        commitment: allFormule.SEMESTRIEL, price: 1800, priceLabel: 'Semestriel 1800 Eur / 6 mois',
        crossPrice: '3000 Eur / 6 mois', discount: '-40%',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALPHACREDIT,
        maxFacility: 10
      },
      {
        id: 10, code: 'VFA',
        commitment: allFormule.ANNUEL, price: 3000, priceLabel: 'Annuel 3000 Eur / an',
        crossPrice: '5500 Eur / an', discount: '-45%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 12
      },
      {
        id: 11, code: 'VFA',
        commitment: allFormule.ANNUEL, price: 3000, priceLabel: 'Annuel 3000 Eur / an',
        crossPrice: '5500 Eur / an', discount: '-45%',
        methods: [allPaymentType.CARD, allPaymentType.SEPA],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 4
      },
      {
        id: 12, code: 'VFA_10X',
        commitment: allFormule.ANNUEL, price: 3000, priceLabel: 'Annuel 3000 Eur / an',
        crossPrice: '5500 Eur / an', discount: '-45%',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALPHACREDIT,
        maxFacility: 10
      },
    ]
  },
  'Formation-Alphabody': {
    id: 1,
    code: 'FH',
    name: 'Formation Alphabody en 3 fois',
    product: sellableProducts.FORMATION_HOMME,
    isSellerNeeded: true,
    defaultFormule: 2,
    formules: [
      {
        id: 1, code: 'FHM',
        commitment: allFormule.MENSUEL, price: 177, priceLabel: 'Mensuel 177 Eur / mois',
        methods: [allPaymentType.CARD]
      },
      {
        id: 2, code: 'FHT',
        commitment: allFormule.TRIMESTRIEL, price: 497, priceLabel: 'Trimestriel 497 Eur / 3 mois',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 3
      }
    ]
  },
  'Formation_Alphabody': {
    id: 2,
    code: 'FF',
    name: 'Formation Alphabody en 3 fois',
    product: sellableProducts.FORMATION_FEMME,
    isSellerNeeded: true,
    defaultFormule: 2,
    formules: [
      {
        id: 1, code: 'FFM',
        commitment: allFormule.MENSUEL, price: 177, priceLabel: 'Mensuel 177 Eur / mois',
        methods: [allPaymentType.CARD]
      },
      {
        id: 2, code: 'FFT',
        commitment: allFormule.TRIMESTRIEL, price: 497, priceLabel: 'Trimestriel 497 Eur / 3 mois',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 3
      }
    ]
  },
  'Coaching-VIP-Alphabody-Upsell': {
    id: 3,
    code: 'VH',
    name: 'Coaching VIP Alphabody',
    product: sellableProducts.UPSELL_VIP_HOMME,
    isSellerNeeded: true,
    defaultFormule: 1,
    formules: [
      {
        id: 1, code: 'VHA',
        commitment: allFormule.ONE_MORE_YEAR,
        price: 2500,
        priceLabel: 'Annuel 2500 Eur / Une année supplémentaire',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 12
      },
      {
        id: 2, code: 'VHS',
        commitment: allFormule.SIX_ANOTHER_MONTHS,
        price: 1000,
        priceLabel: 'Semestriel 1000 Eur / Six mois supplémentaires',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 6
      }
    ]
  },
  'Coaching-VIP-Alphabody-Renouvellement': {
    id: 3,
    code: 'VH',
    name: 'Coaching VIP Alphabody',
    product: sellableProducts.RENEWAL_VIP_HOMME,
    isSellerNeeded: true,
    defaultFormule: 6,
    formules: [
      {
        id: 1, code: 'VHT',
        commitment: allFormule.TRIMESTRIEL, price: 1000, priceLabel: 'Trimestriel 1000 Eur / trimestre',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 12
      },
      {
        id: 2, code: 'VHT',
        commitment: allFormule.TRIMESTRIEL, price: 1000, priceLabel: 'Trimestriel 1000 Eur / trimestre',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 10
      },
      {
        id: 3, code: 'VHT_10X',
        commitment: allFormule.TRIMESTRIEL, price: 1000, priceLabel: 'Trimestriel 1000 Eur / trimestre',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALPHACREDIT,
        maxFacility: 10
      },
      {
        id: 4, code: 'VHS',
        commitment: allFormule.SEMESTRIEL, price: 1600, priceLabel: 'Semestriel 1600 Eur / semestre',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 12
      },
      {
        id: 5, code: 'VHS',
        commitment: allFormule.SEMESTRIEL, price: 1600, priceLabel: 'Semestriel 1600 Eur / semestre',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 10
      },
      {
        id: 6, code: 'VHS_10X',
        commitment: allFormule.SEMESTRIEL, price: 1600, priceLabel: 'Semestriel 1600 Eur / semestre',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALPHACREDIT,
        maxFacility: 10
      },
      {
        id: 7, code: 'VHA',
        commitment: allFormule.ANNUEL, price: 2400, priceLabel: 'Annuel 2400 Eur / an',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 12
      },
      {
        id: 8, code: 'VHA',
        commitment: allFormule.ANNUEL, price: 2400, priceLabel: 'Annuel 2400 Eur / an',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 10
      },
      {
        id: 9, code: 'VHA_12X',
        commitment: allFormule.ANNUEL, price: 2400, priceLabel: 'Annuel 2400 Eur / an',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALPHACREDIT,
        maxFacility: 12
      },
      {
        id: 10, code: 'VHA_P',
        commitment: allFormule.ANNUEL, price: 1600, priceLabel: 'Annuel 1600 Eur / an',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 12
      },
      {
        id: 11, code: 'VHA_P',
        commitment: allFormule.ANNUEL, price: 1600, priceLabel: 'Annuel 1600 Eur / an',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 10
      },
      {
        id: 12, code: 'VHA_P_12X',
        commitment: allFormule.ANNUEL, price: 1600, priceLabel: 'Annuel 1600 Eur / an',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALPHACREDIT,
        maxFacility: 12
      },
      {
        id: 13, code: 'VHM_P',
        commitment: allFormule.MENSUEL, price: 160, priceLabel: 'Mensuel 160 Eur / mois',
        methods: [allPaymentType.CARD],
      }
    ]
  },
  'Coaching_VIP_Alphabody_Renouvellement': {
    id: 4,
    code: 'VF',
    name: 'Coaching VIP Alphabody',
    product: sellableProducts.RENEWAL_VIP_FEMME,
    isSellerNeeded: true,
    defaultFormule: 6,
    formules: [
      {
        id: 1, code: 'VFT',
        commitment: allFormule.TRIMESTRIEL, price: 1000, priceLabel: 'Trimestriel 1000 Eur / trimestre',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 12
      },
      {
        id: 2, code: 'VFT',
        commitment: allFormule.TRIMESTRIEL, price: 1000, priceLabel: 'Trimestriel 1000 Eur / trimestre',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 10
      },
      {
        id: 3, code: 'VFT_10X',
        commitment: allFormule.TRIMESTRIEL, price: 1000, priceLabel: 'Trimestriel 1000 Eur / trimestre',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALPHACREDIT,
        maxFacility: 10
      },
      {
        id: 4, code: 'VFS',
        commitment: allFormule.SEMESTRIEL, price: 1600, priceLabel: 'Semestriel 1600 Eur / semestre',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 12
      },
      {
        id: 5, code: 'VFS',
        commitment: allFormule.SEMESTRIEL, price: 1600, priceLabel: 'Semestriel 1600 Eur / semestre',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 10
      },
      {
        id: 6, code: 'VFS_10X',
        commitment: allFormule.SEMESTRIEL, price: 1600, priceLabel: 'Semestriel 1600 Eur / semestre',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALPHACREDIT,
        maxFacility: 10
      },
      {
        id: 7, code: 'VFA',
        commitment: allFormule.ANNUEL, price: 2400, priceLabel: 'Annuel 2400 Eur / an',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 12
      },
      {
        id: 8, code: 'VFA',
        commitment: allFormule.ANNUEL, price: 2400, priceLabel: 'Annuel 2400 Eur / an',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 10
      },
      {
        id: 9, code: 'VFA_12X',
        commitment: allFormule.ANNUEL, price: 2400, priceLabel: 'Annuel 2400 Eur / an',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALPHACREDIT,
        maxFacility: 12
      },
      {
        id: 10, code: 'VFA_P',
        commitment: allFormule.ANNUEL, price: 1600, priceLabel: 'Annuel 1600 Eur / an',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALMA,
        maxFacility: 12
      },
      {
        id: 11, code: 'VFA_P',
        commitment: allFormule.ANNUEL, price: 1600, priceLabel: 'Annuel 1600 Eur / an',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.LENBOX,
        maxFacility: 10
      },
      {
        id: 12, code: 'VFA_P_12X',
        commitment: allFormule.ANNUEL, price: 1600, priceLabel: 'Annuel 1600 Eur / an',
        methods: [allPaymentType.CARD],
        facilityPaymentProvider: allPaymentProvider.ALPHACREDIT,
        maxFacility: 12
      },
      {
        id: 13, code: 'VFM_P',
        commitment: allFormule.MENSUEL, price: 160, priceLabel: 'Mensuel 160 Eur / mois',
        methods: [allPaymentType.CARD],
      }
    ]
  },
  'Formation-Alphabody-Renouvellement': {
    id: 1,
    code: 'FH',
    name: 'Formation Alphabody en 3 fois',
    product: sellableProducts.RENEWAL_FORMATION_HOMME,
    isSellerNeeded: true,
    defaultFormule: 1,
    formules: [
      {
        id: 1, code: 'FHM',
        commitment: allFormule.MENSUEL, price: 47, priceLabel: 'Mensuel 47 Eur / mois',
        methods: [allPaymentType.CARD]
      }
    ]
  },
  'Formation_Alphabody_Renouvellement': {
    id: 2,
    code: 'FF',
    name: 'Formation Alphabody en 3 fois',
    product: sellableProducts.RENEWAL_FORMATION_FEMME,
    isSellerNeeded: true,
    defaultFormule: 1,
    formules: [
      {
        id: 1, code: 'FFM',
        commitment: allFormule.MENSUEL, price: 47, priceLabel: 'Mensuel 47 Eur / mois',
        methods: [allPaymentType.CARD]
      }
    ]
  },
}
