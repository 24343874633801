import './accueil.css'

import React, {useEffect, useState} from 'react';

import Logo from "../../components/Logo";
import {useStyles} from "../Checkout-auto/styles";
import {
  allFormule, allOffers, allPaymentProvider, sellableProducts
} from "../../store/offers/products/productsWithSeller";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectedFormule, selectedProduct} from "../../store/offers/offersActions";
import {Collapse} from "@mui/material";
import {FiCopy, FiExternalLink} from "react-icons/all";
import {Alert} from "@mui/lab";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const gender = {
  homme: 'homme', femme: 'femme',
}

const vipOffersAvailableByGender = {
  homme: ['Annuel', 'Semestriel', 'Trimestriel', 'Mensuel'],
  femme: ['Annuel', 'Semestriel', 'Trimestriel', 'Mensuel']
}

export const encodedUriToRenewalFormulaWithStripeOnlyMapping = ({
  'AzDDdeP': allFormule.MENSUEL,
})

export const encodedUriToRenewalFormulaWithAlmaMapping = ({
  'jHijKvN': allFormule.ANNUEL,
  'EkSECzB': allFormule.ANNUEL,
  'mqWknWp': allFormule.SEMESTRIEL,
  'EkSECpB': allFormule.TRIMESTRIEL,
})

export const encodedUriToRenewalFormulaWithLenboxMapping = ({
  'jHijKuN': allFormule.ANNUEL,
  'EkSECmB': allFormule.ANNUEL,
  'mqWknTp': allFormule.SEMESTRIEL,
  'EkSECyB': allFormule.TRIMESTRIEL,
})

export const encodedUriToProductFormulaWithAlphabodyCreditMapping = ({
  'derniere-chance-2ycad': allFormule.ANNUEL,
  'derniere-chance-4cxpc': allFormule.ANNUEL,
  'derniere-chance-1zdxc': allFormule.SEMESTRIEL,
  'derniere-chance-Ocxpc': allFormule.TRIMESTRIEL,
})

export const renewalVIPFormulaMapping = (product) => ({
  'Annuel': {
    formules: [{
      name: 'Annuel (Stripe + Alma 12x max)',
      encoded: 'jHijKvN',
      formule: allOffers[product].formules
        .filter(f => f.commitment === allFormule.ANNUEL)
        .filter(f => !f.code.includes('P'))
        .find(f => f.facilityPaymentProvider === allPaymentProvider.ALMA)
    }, {
      name: 'Annuel (Stripe + Lenbox 10x max)', encoded: 'jHijKuN', formule: allOffers[product].formules
        .filter(f => f.commitment === allFormule.ANNUEL)
        .filter(f => !f.code.includes('P'))
        .find(f => f.facilityPaymentProvider === allPaymentProvider.LENBOX)
    }, {
      name: 'Annuel (AlphaCrédit 12x)', encoded: 'derniere-chance-2ycad', formule: allOffers[product].formules
        .filter(f => f.commitment === allFormule.ANNUEL)
        .filter(f => !f.code.includes('P'))
        .find(f => f.facilityPaymentProvider === allPaymentProvider.ALPHACREDIT)
    }, {
      name: 'Annuel Phenix (Stripe + Alma 12x max)', encoded: 'EkSECzB', formule: allOffers[product].formules
        .filter(f => f.commitment === allFormule.ANNUEL)
        .filter(f => f.code.includes('P'))
        .find(f => f.facilityPaymentProvider === allPaymentProvider.ALMA)
    }, {
      name: 'Annuel Phenix (Stripe + Lenbox 10x max)', encoded: 'EkSECmB', formule: allOffers[product].formules
        .filter(f => f.commitment === allFormule.ANNUEL)
        .filter(f => f.code.includes('P'))
        .find(f => f.facilityPaymentProvider === allPaymentProvider.LENBOX)
    }, {
      name: 'Annuel Phenix (AlphaCrédit 12x)',
      encoded: 'derniere-chance-4cxpc',
      formule: allOffers[product].formules
        .filter(f => f.commitment === allFormule.ANNUEL)
        .filter(f => f.code.includes('P'))
        .find(f => f.facilityPaymentProvider === allPaymentProvider.ALPHACREDIT)
    }]
  },
  'Semestriel': {
    formules: [{
      name: 'Semestriel (Stripe + Alma 12x max)', encoded: 'mqWknWp', formule: allOffers[product].formules
        .filter(f => f.commitment === allFormule.SEMESTRIEL)
        .find(f => f.facilityPaymentProvider === allPaymentProvider.ALMA)
    }, {
      name: 'Semestriel (Stripe + Lenbox 10x max)', encoded: 'mqWknTp', formule: allOffers[product].formules
        .filter(f => f.commitment === allFormule.SEMESTRIEL)
        .find(f => f.facilityPaymentProvider === allPaymentProvider.LENBOX)
    }, {
      name: 'Semestriel (AlphaCrédit 10x max)', encoded: 'derniere-chance-1zdxc', formule: allOffers[product].formules
        .filter(f => f.commitment === allFormule.SEMESTRIEL)
        .find(f => f.facilityPaymentProvider === allPaymentProvider.ALPHACREDIT)
    }]
  },
  'Trimestriel': {
    formules: [{
      name: 'Trimestriel (Stripe + Alma 12x max)', encoded: 'EkSECpB', formule: allOffers[product].formules
        .filter(f => f.commitment === allFormule.TRIMESTRIEL)
        .find(f => f.facilityPaymentProvider === allPaymentProvider.ALMA)
    }, {
      name: 'Trimestriel (Stripe + Lenbox 10x max)', encoded: 'EkSECyB', formule: allOffers[product].formules
        .filter(f => f.commitment === allFormule.TRIMESTRIEL)
        .find(f => f.facilityPaymentProvider === allPaymentProvider.LENBOX)
    }, {
      name: 'Trimestriel (AlphaCrédit 10x max)', encoded: 'derniere-chance-Ocxpc', formule: allOffers[product].formules
        .filter(f => f.commitment === allFormule.TRIMESTRIEL)
        .find(f => f.facilityPaymentProvider === allPaymentProvider.ALPHACREDIT)
    }]
  },
  'Mensuel': {
    formules: [{
      name: 'Mensuel Phenix (Stripe)', encoded: 'AzDDdeP', formule: allOffers[product].formules
        .find(f => f.commitment === allFormule.MENSUEL)
    }]
  },
})

export const renewalFormationFormulaMapping = (product) => ({
  'Mensuel': {
    formules: [{
      name: 'Mensuel (Stripe)', formule: allOffers[product].formules
        .filter(f => f.commitment === allFormule.MENSUEL)[0]
    }]
  },
})

const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#EA6639',
  cursor: 'pointer',
  marginTop: '5px'
}));

const FragmentUrls = ({gender, email}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const product = gender === 'homme' ? sellableProducts.RENEWAL_VIP_HOMME : sellableProducts.RENEWAL_VIP_FEMME;

  const dispatchThenRoute = (product, formule, myPath) => {
    if (!email) {
      alert('L\'email du client à renouveler est obligatoire.');
    } else {
      dispatch(selectedProduct(product));
      if (formule) {
        dispatch(selectedFormule(formule.formule));
      }
      navigate(myPath);
    }
  };

  const handleCopied = () => {
    if (!email) {
      alert('L\'email du client à renouveler est obligatoire.');
    } else {
      setCopied(true);
      setTimeout(() => setCopied(false), 3500);
    }
  }

  return (<div className={classes.root}>
    {<><Collapse in={copied}><Alert severity={"success"}>Copier</Alert></Collapse><br/></>}
    <Logo gender={gender}/>
    {vipOffersAvailableByGender[gender].map(commitmentPeriod => renewalVIPFormulaMapping(product)[commitmentPeriod].formules.map(formule => <>
      <h4>
        {formule.name}
        <a onClick={() => {
          dispatchThenRoute(product, formule, `/renew-vip-${gender}/${formule.encoded}?email=${email}`)
        }}>
          <FiExternalLink style={{marginLeft: '5px', marginRight: '5px', cursor: 'pointer'}}/>
        </a>
        <CopyToClipboard text={`${window.location.origin}/renew-vip-${gender}/${formule.encoded}?email=${email}`}
                         onCopy={() => handleCopied()}>
          <Item>
            <FiCopy style={{marginLeft: '5px', marginRight: '5px'}}/>
            {`${window.location.origin}/renew-vip-${gender}/${formule.encoded}`}
          </Item>
        </CopyToClipboard>
      </h4>
    </>))}
    {<h4>
      Formation {gender}
      <a onClick={() => {
        const sellableProduct = sellableProducts[`FORMATION_${gender.toUpperCase()}`];
        // Null car une seule formule mensuelle pour le moment, selectionné dans le guard coté App.js
        dispatchThenRoute(sellableProduct, null, `/renew-form-${gender}?comp=false&email=${email}`)
      }}>
        <FiExternalLink style={{marginLeft: '5px', marginRight: '5px', cursor: 'pointer'}}/>
      </a>
      <CopyToClipboard text={`${window.location.origin}/renew-form-${gender}?comp=false&email=${email}`}
                       onCopy={() => handleCopied()}>
        <Item>
          <FiCopy style={{marginLeft: '5px', marginRight: '5px'}}/>
          {`${window.location.origin}/renew-form-${gender}`}
        </Item>
      </CopyToClipboard>
    </h4>}
  </div>)
}

const RenewalHome = () => {
  const [email, setEmail] = useState();

  useEffect(() => {
    document.body.classList.add("page-neutre");
  }, []);

  return (<div className='App center'>
    <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
      <Grid item xs={12}>
        <div className='routing-table mail'>
          <h2>RENOUVELLEMENT</h2>
          <span>Email du client</span>
          <input value={email} onChange={(e) => setEmail(e.target.value)}/>
        </div>
      </Grid>
      <Grid item xs={6}>
        <FragmentUrls gender={gender.homme} email={email}/>
      </Grid>
      <Grid item xs={6}>
        <FragmentUrls gender={gender.femme} email={email}/>
      </Grid>
    </Grid>
  </div>);
}

export default RenewalHome;