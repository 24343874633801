import './index.css';

import App from './App';
import { Elements } from '@stripe/react-stripe-js';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import dotenv from 'dotenv';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter } from 'react-router-dom'
import store from "./store/store";

dotenv.config();

/*--------------------URL STRIPE------------------------*/
let key = process.env.REACT_APP_STRIPE_KEY;
/*----------------------------------------------------*/
const stripePromise = loadStripe(key);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        {/* <React.StrictMode> */}
        {key === 'pk_test_phXrFU3Ei56q0TtB5ZN5kQjO' ? (
          <div className='alert'>
            <div>
              {`⚠️  Stripe est en mode test  ⚠️`}
            </div>
            <div>
              {`Sans 3dSecure => 4242424242424242 / Avec 3dSecure => 4000002500003155`}<br/>
              {`Iban => FR1420041010050500013M02606 / FR3020041010050500013M02609 (3min delay)`}
            </div>
          </div>
        ) : null}
        <App />
        {/* </React.StrictMode> */}
      </Elements>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
