import React, {useEffect, useState} from 'react';

import Logo from "../../components/Logo";
import {useStyles} from "../Checkout-auto/styles";
import {allFormule, allOffers, sellableProducts} from "../../store/offers/products/productsWithSeller";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectedFormule, selectedProduct} from "../../store/offers/offersActions";
import {FiCopy, FiExternalLink} from "react-icons/all";
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Alert} from "@mui/lab";
import {Collapse} from "@mui/material";

const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#EA6639',
  cursor: 'pointer',
  marginTop: '5px'
}));

const FragmentUrls = ({gender, style}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const product = gender === 'homme' ? sellableProducts.FORMATION_HOMME : sellableProducts.FORMATION_FEMME;

  const dispatchThenRoute = (product, myPath) => {
    dispatch(selectedProduct(product));
    navigate(myPath);
  };

  const handleCopied = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3500);
  }

  return (
    <div className={classes.root} style={style}>
      {<><Collapse in={copied}><Alert severity={"success"}>Copier</Alert></Collapse><br/></> }
      <Logo gender={gender}/>
      {
        <h4>
          {allOffers[product].name}
          <a onClick={() => dispatchThenRoute(product, `/form-${gender}`)}>
            <FiExternalLink style={{marginLeft: '5px', marginRight: '5px', cursor: 'pointer'}}/>
          </a>
          <CopyToClipboard text={`https://paiement.alphabody.fr/form-${gender}`} onCopy={() => handleCopied()}>
            <Item>
              <FiCopy style={{marginLeft: '5px', marginRight: '5px'}}/>
              {`https://paiement.alphabody.fr/form-${gender}`}
            </Item>
          </CopyToClipboard>
        </h4>
      }
    </div>
  )
}

const AllFormationUrls = () => {

  useEffect(() => {
    document.body.classList.add("page-neutre");
    return () => {
    };
  }, []);

  return (
    <div className='App center'>
      <FragmentUrls gender={'homme'} />

      <FragmentUrls gender={'femme'} style={{marginLeft: '50px'}}/>
    </div>
  );
}

export default AllFormationUrls;