export const GET_ALL_SELLERS = 'GET_ALL_SELLERS';
export const GET_ALL_SELLERS_SUCCEEDED = 'GET_ALL_SELLERS_SUCCEEDED';
export const SAVE_THE_SALE = 'SAVE_THE_SALE';
export const SAVE_THE_SALE_SUCCEEDED = 'SAVE_THE_SALE_SUCCEEDED';
export const POST_SALE_TO_INTEGROMAT = 'POST_SALE_TO_INTEGROMAT';
export const POST_SALE_TO_INTEGROMAT_SUCCEEDED = 'POST_SALE_TO_INTEGROMAT_SUCCEEDED';

export const getAllSellers = () => ({
    type: GET_ALL_SELLERS,
});
export const getAllSellersSucceeded = (payload) => ({
    type: GET_ALL_SELLERS_SUCCEEDED,
    payload,
});
export const saveTheSale = (payload) => ({
    type: SAVE_THE_SALE,
    payload,
});
export const saveTheSaleSucceeded = () => ({
    type: SAVE_THE_SALE_SUCCEEDED
});
export const postSaleToIntegromat = () => ({
    type: POST_SALE_TO_INTEGROMAT
});

export const postSaleToIntegromatSucceeded = () => ({
    type: POST_SALE_TO_INTEGROMAT_SUCCEEDED
});
