import React, {useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {Controller} from "react-hook-form";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import IbanInformation from "./ibanInformation";
import {useDispatch, useSelector} from "react-redux";
import {selectedFormule} from "../../../../store/offers/offersActions";
import {allPaymentType} from "../../../../store/offers/products/productsWithSeller";
import Complements from "../../products/complements";

const PaymentPerSepa = ({form, product, withComplement}) => {
  const {register, control} = form;
  const dispatch = useDispatch();
  const [selectedFormuleId, changeSelectedFormuleId] = useState(product.defaultFormule);
  const {renewal, formule} = useSelector((state) => state.offerState);

  const handleFormuleChanged = (fn, value) => {
    fn(parseInt(value));
    changeSelectedFormuleId(parseInt(value))
    const formule = product.formules.find(f => f.id === parseInt(value));
    dispatch(selectedFormule(formule));
  }

  const getPriceLabel = (formule) => withComplement || formule.withComplement ?
    `${formule.priceLabel} avec le complément de ton choix` :
    (<div className='priceLine'><p>{formule.priceLabel}</p>
      {formule.crossPrice ? <p className='discountPrice'><strike>{formule.crossPrice}</strike> soit {formule.discount}</p> : ''}
    </div>);

  const shouldDisplayComplement = () => {
    const selectedFormule = product.formules.find(f => f.id === selectedFormuleId);
    return withComplement || selectedFormule.withComplement;
  }

  return (
    <div className='paymentFormCtn'>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Choix de la formule:</FormLabel>
        {
          renewal &&
          <Controller control={control} name="formule" rules={{required: true}}
                      defaultValue={selectedFormuleId}
                      render={({field: {onChange}}) => (
                        <RadioGroup value={selectedFormuleId}
                                    onChange={(e, value) => handleFormuleChanged(onChange, value)}>
                          {product.formules
                            .filter(formule => formule.methods.includes(allPaymentType.SEPA))
                            .map(formule =>
                              <>
                                <FormControlLabel value={formule.id} control={<Radio/>} label={getPriceLabel(formule)}/>
                              </>
                            )}
                        </RadioGroup>)}
          />
        }
        {
          !renewal &&
          <RadioGroup>
            <FormControlLabel value={formule.id} control={<Radio checked={true}/>} label={getPriceLabel(formule)}/>
          </RadioGroup>
        }
      </FormControl>
      {shouldDisplayComplement() && <div><br/><Complements form={{register}}/></div>}
      {!shouldDisplayComplement() && <br/>}
      <IbanInformation />
    </div>
  )
}

export default PaymentPerSepa;