import {
    CREATE_LENBOX_PAYMENT, GET_LENBOX_PAYMENT_INFOS_SUCCEEDED, UPDATE_LENBOX_ERROR
} from "./lenboxActions";

export const lenboxReducer = (state = {}, action = {}) => {
    switch (action.type) {
        case CREATE_LENBOX_PAYMENT:
            return {
                ...state,
                paymentInfos: action.payload,
                paymentProcessing: true,
            }
        case UPDATE_LENBOX_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case GET_LENBOX_PAYMENT_INFOS_SUCCEEDED:
            return {
                ...state,
                paymentInfos: action.payload,
            }
        default:
            return state
    }
}
