import React from 'react';
import {CardElement} from "@stripe/react-stripe-js";
import {FaCcAmex, FaCcMastercard, FaCcVisa} from "react-icons/fa";
import {Alert} from "@mui/lab";
import {useDispatch, useSelector} from "react-redux";
import {cardInformationFulfilled} from "../../../../../store/payment/stripe/stripeActions";
import SuccessRedirection from "./successRedirection";

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const CardInformation = ({shouldDisplay}) => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.stripeState.error);

  const handleCardInformationChanged = (e) => {
    dispatch(cardInformationFulfilled(!e.empty));
  }

  return (
    shouldDisplay &&
    <div>
      <CardElement id='card-element' options={{cardStyle, hidePostalCode: true}}
                   onChange={handleCardInformationChanged}/>
      <div>
        <FaCcMastercard className='secure-icons'/>
        <FaCcVisa className='secure-icons'/>
        <FaCcAmex className='secure-icons'/>
      </div>

      {error && (
        <div className='card-error' role='alert'>
          <Alert severity='error'>{error}</Alert>
        </div>
      )}
      <SuccessRedirection />
    </div>
  )
}

export default CardInformation;
