import { makeStyles } from '@material-ui/core/styles';

//---------------------------- STYLES ---------------------------------------

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  breakpoints: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
    },
  },
  media: {
    [theme.breakpoints.down('md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('md')]: {
      width: '20%',
    },
  },
  text: {
    [theme.breakpoints.down('md')]: {
      width: '90%',
      margin: '0 auto',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
      margin: '0 auto',
    },
  },
}));

export const styles = {
  media: {
    margin: '10px 10px',
  },
  content: {
    width: '70%',
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '1rem',
  },
  price: {
    color: '#fa755a',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  oldPrice: {
    textDecoration: 'line-through',
    color: '#aaa',
  },
  formCheckBox: {
    width: '100%',
    margin: '1rem auto',
    backgroundColor: '#9eade980',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  box: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

//style input carte
export const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};
