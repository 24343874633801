import React, {useEffect} from 'react'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import {getAllSellers} from "../../../store/sales/salesActions";
import {Alert} from "@material-ui/lab";

const Seller = ({form, isSellerNeeded, isRenewal}) => {
  const {register, errors, setValue} = form;
  const sellers = useSelector((state) => state.salesState.coachs);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSellerNeeded) {
      dispatch(getAllSellers())
    }
  }, [isSellerNeeded])

  useEffect(() => {
    if(isRenewal) {
      // 48 is Samim seller id (in production), he is the only renewer actually
      setValue("coachSeller", process.env.NODE_ENV === 'development' ? "16" : "48")
    }
  }, [isRenewal])

  const isRealSeller = seller => seller.firstname !== "Vente" && seller.lastname !== "Auto";

  return (
    <div className='inputs' id='seller'>
      {(isSellerNeeded && !isRenewal) &&
        <FormControl>
          <InputLabel id='coach-seller'>Coach *</InputLabel>
          <Select labelId='coach-seller' id='simple-select' {...register("coachSeller", { required: true })}>
            {sellers.map((seller) => {
              if (isRealSeller(seller)) {
                return (
                  <MenuItem key={seller.id} value={seller.id}>
                    {seller.firstname} {seller.lastname}
                  </MenuItem>
                );
              }
            })}
          </Select>
          {errors.coachSeller?.type === 'required' && <Alert severity='error'>Merci de renseigner ce champ</Alert>}
        </FormControl>}
    </div>
  )
}

export default Seller