import React from "react";

function IconWoman({circleColor, topMainColor, topShadowColor, topLightColor, topUnderwearColor}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="564"
      height="564"
      viewBox="0 0 564 564"
    >
      <defs>
        <path
          id="path_1"
          d="M0 282C0 126.256 126.256 0 282 0s282 126.256 282 282-126.256 282-282 282S0 437.744 0 282z"
        ></path>
        <path id="path_2" d="M0 0h464v464H0V0z"></path>
        <clipPath id="mask_1">
          <use xlinkHref="#path_1"></use>
        </clipPath>
        <clipPath id="mask_2">
          <use xlinkHref="#path_2"></use>
        </clipPath>
      </defs>
      <g>
        <path
          fill={circleColor}
          fillRule="evenodd"
          stroke="none"
          d="M0 282C0 126.256 126.256 0 282 0s282 126.256 282 282-126.256 282-282 282S0 437.744 0 282z"
        ></path>
        <g clipPath="url(#mask_1)">
          <g
            fillRule="evenodd"
            stroke="none"
            transform="translate(51.971 100.117)"
          >
            <path fill="none" d="M0 0h464v464H0V0z"></path>
            <g clipPath="url(#mask_2)">
              <path
                fill="#FFDFBA"
                d="M347.218 355.48c-.232.232-23.421 23.459-23.7 23.63 6.47 11.05 10.18 23.91 10.18 37.63V456c0 4.42-3.58 8-8 8h-292c-4.42 0-8-3.58-8-8v-39.26c0-13.72 3.71-26.58 10.18-37.63-.269-.164 1.628 1.698-23.7-23.63-9.4-9.4-10.27-24.34-2.04-34.77 10.038-12.843 7.122-27.663-1.91-36.18-9.91-9.35-11.01-24.73-2.53-35.39l11.52-14.48a26.212 26.212 0 004.62-23.73l-7.44-25.23c-3.81-12.89 2.76-26.58 15.2-31.67l9.83-4.03a26.203 26.203 0 0016.27-24.25V100c0-55.23 44.77-100 100-100h48c55.227 0 100 44.758 100 100v25.75c0 10.64 6.43 20.22 16.27 24.25l9.83 4.03c12.44 5.09 19.01 18.78 15.2 31.67l-7.44 25.23a26.212 26.212 0 004.62 23.73l11.52 14.48c8.487 10.686 7.376 26.03-2.53 35.39-9.89 9.32-11.01 24.65-2.59 35.31 9.566 12.239 7.533 26.747-1.36 35.64z"
                transform="translate(52.302)"
              ></path>
              <path
                fill="#FFDFBA"
                d="M12.18 355.48c.232.232 23.42 23.459 23.7 23.63-6.47 11.05-10.18 23.91-10.18 37.63V456c0 4.42 3.58 8 8 8h292c4.42 0 8-3.58 8-8v-39.26c0-13.72-3.71-26.58-10.18-37.63.269-.164-1.628 1.698 23.7-23.63 9.4-9.4 10.27-24.34 2.04-34.77-10.038-12.843-7.122-27.663 1.91-36.18 9.91-9.35 11.01-24.73 2.53-35.39l-11.52-14.48a26.212 26.212 0 01-4.62-23.73L345 185.7c3.81-12.89-2.76-26.58-15.2-31.67l-9.83-4.03a26.203 26.203 0 01-16.27-24.25V100c0-55.23-44.77-100-100-100h-48c-55.227 0-100 44.758-100 100v25.75c0 10.64-6.43 20.22-16.27 24.25l-9.83 4.03c-12.44 5.09-19.01 18.78-15.2 31.67l7.44 25.23c2.42 8.19.69 17.05-4.62 23.73L5.7 249.14c-8.487 10.686-7.376 26.03 2.53 35.39 9.89 9.32 11.01 24.65 2.59 35.31-9.566 12.239-7.533 26.747 1.36 35.64z"
                transform="translate(52.3)"
              ></path>
              <path
                fill="#C78640"
                d="M87.791 178.836a7.994 7.994 0 00-7.093 7.946v15.349c0 41.26 25.25 76.64 61.15 91.5l-.15.369v6.83c0 22.742-18.332 41.074-40.95 41.34-28.072 0-51.915 14.815-64.87 36.94-.279-.171-23.468-23.398-23.7-23.63-9.4-9.4-10.27-24.34-2.04-34.77 10.038-12.843 7.122-27.663-1.91-36.18-9.91-9.35-11.01-24.73-2.53-35.39l11.52-14.48a26.212 26.212 0 004.62-23.73l-7.44-25.23c-3.81-12.89 2.76-26.58 15.2-31.67l9.83-4.03a26.203 26.203 0 0016.27-24.25V100c0-55.228 44.772-100 100-100h48c55.227 0 100 44.759 100 100v25.75c0 10.64 6.43 20.22 16.27 24.25l9.83 4.03c12.44 5.09 19.01 18.78 15.2 31.67l-7.44 25.23a26.212 26.212 0 004.62 23.73l11.52 14.48c8.486 10.685 7.378 26.029-2.53 35.39-9.236 8.703-11.782 23.55-1.91 36.18 8.23 10.43 7.36 25.37-2.04 34.77-.232.232-23.421 23.459-23.7 23.63-12.848-21.942-36.593-36.94-64.87-36.94-22.609-.266-40.95-18.587-40.95-41.34V294l-.15-.38c35.9-14.86 61.15-50.22 61.15-91.49v-49.313a7.98 7.98 0 00-5.256-7.513c-17.4-6.292-32.091-18.416-41.536-34.614-2.759-4.731-9.384-5.284-12.962-1.137-33.479 38.805-79.948 63.436-131.153 69.283z"
                transform="translate(52.302)"
              ></path>
              <path
                fill="#FFDAAB"
                d="M192.744 38.542A7.978 7.978 0 01198 46.055v49.313c0 41.27-25.25 76.63-61.15 91.49C71.913 213.812 0 165.927 0 95.368v-15.35a7.994 7.994 0 017.093-7.946c51.118-5.837 97.617-30.41 131.153-69.28 3.578-4.148 10.203-3.596 12.962 1.136 9.436 16.184 24.117 28.315 41.536 34.614z"
                transform="translate(133 106.763)"
              ></path>
              <path
                fill={topMainColor ? topMainColor : "#F4A081"}
                d="M308 78.28v39.26c0 4.42-3.58 8-8 8H8c-4.42 0-8-3.58-8-8V78.28C0 37.129 33.358 3.71 74.57 3.71A41.374 41.374 0 0091.75 0l.25.54 57.1 44.21a8.014 8.014 0 009.8 0L216 .54l.25-.54c5.1 2.32 10.75 3.64 16.7 3.71 42.818 0 75.05 34.345 75.05 74.57z"
                transform="translate(78 338.46)"
              ></path>
              <path
                fill="#DB9951"
                d="M238.828 6.352c12.78 4.792 9.117 23.73-4.532 23.65-.497-.003-48.101-.002-48.598-.002-55.228 0-100 44.772-100 100v25.75c0 10.64-6.43 20.22-16.27 24.25l-9.83 4.03c-12.44 5.09-19.01 18.78-15.2 31.67l7.44 25.23c2.42 8.19.69 17.05-4.62 23.73l-11.52 14.48c-8.48 10.66-7.38 26.04 2.53 35.39 9.873 9.311 11.02 24.628 2.59 35.31-7.06 9.033-7.829 18.884-4.24 27.329.406.959-.818 1.842-1.586 1.087-4.284-4.214-22.608-22.57-22.814-22.775-8.717-8.717-11.085-23.198-1.36-35.64 8.438-10.692 7.274-26.009-2.59-35.31-9.91-9.35-11.01-24.73-2.53-35.39l11.52-14.48a26.212 26.212 0 004.62-23.73l-7.44-25.231c-3.81-12.89 2.76-26.58 15.2-31.67l9.83-4.03a26.203 26.203 0 0016.27-24.25V100c0-55.228 44.772-100 100-100h48a99.935 99.935 0 0135.13 6.352z"
                transform="translate(52.302)"
              ></path>
              <path
                fill="#FFE9CF"
                d="M76.47 89.158c16.194 10.253 3.539 35.069-14.254 27.943C25.672 102.465 0 66.761 0 25.167V9.817c0-4.07 3.05-7.48 7.09-7.94 3.715-.428 7.72-.989 11.967-1.73 5.63-.982 10.919 3.066 11.383 8.762 3.015 37.04 21.238 64.552 46.03 80.25z"
                transform="translate(133 176.963)"
              ></path>
              <path
                fill={topLightColor ? topLightColor : "#FBB89F"}
                d="M104.795 10.45c5.105 3.953 2.403 12.234-4.054 12.26C58.205 22.88 26 57.028 26 97.28v28.26H8a8 8 0 01-8-8V78.28C0 37.82 32.461 3.71 75.05 3.71 81 3.64 86.65 2.32 91.75 0l.25.54 12.795 9.91z"
                transform="translate(78 338.46)"
              ></path>
              <path
                fill={topShadowColor ? topShadowColor : "#E28A69"}
                d="M2.667 10.45C-2.438 14.403.264 22.684 6.721 22.71c42.536.17 74.741 34.318 74.741 74.57v28.26h18a8 8 0 008-8V78.28c0-40.46-32.46-74.57-75.05-74.57-5.95-.07-11.6-1.39-16.7-3.71l-.25.54-12.795 9.91z"
                transform="translate(278.538 338.46)"
              ></path>
              <path
                fill={topUnderwearColor ? topUnderwearColor : "#FBD7C9"}
                d="M74.053 44.748a8.001 8.001 0 009.795 0L140.95.54l.25-.54c5.1 2.32 10.75 3.64 16.7 3.71l-34.95 121.83h-88L0 3.71C5.95 3.64 11.6 2.32 16.7 0l.25.54 57.103 44.208z"
                transform="translate(153.05 338.46)"
              ></path>
              <path
                fill="#FFE4DA"
                d="M16.565 23.25c-2.985.333-6.361.913-10.385 1.99L0 3.71C5.95 3.64 11.6 2.32 16.7 0l.25.54 4.909 3.802c7.521 5.825 4.16 17.853-5.294 18.908z"
                transform="translate(153.05 338.46)"
              ></path>
              <path
                fill="#EFCABC"
                d="M9.417 23.25c2.985.333 6.36.913 10.385 1.99l6.18-21.53c-5.95-.07-11.6-1.39-16.7-3.71l-.25.54-4.91 3.802c-7.52 5.825-4.16 17.853 5.295 18.908z"
                transform="translate(284.968 338.46)"
              ></path>
              <path
                fill="#F2C48A"
                d="M78.26 16.79C53.623 27.103 26.548 27.903 0 16.79 1.356 11.164 1.13 7.52 1.13.37L1.28 0c24.082 9.996 51.571 10.016 75.7 0l.15.37c0 7.153-.226 10.796 1.13 16.42z"
                transform="translate(192.87 293.63)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconWoman;
