import {ofType} from "redux-observable";
import {PAYMENT_SUCCESS} from "./paymentActions";
import {upsertCustomer} from "../customer/customerActions";
import {first, mergeMap} from "rxjs";
import {postSaleToIntegromat} from "../sales/salesActions";

export const paymentSuccess$ = (actions$, state$) => actions$.pipe(
  ofType(PAYMENT_SUCCESS),
  first(),
  mergeMap(_ => [
    upsertCustomer(),
    postSaleToIntegromat()
  ])
)
