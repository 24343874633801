import React from 'react';
import {IbanElement} from "@stripe/react-stripe-js";
import "./iban.css";
import {ibanInformationFulfilled} from "../../../../store/payment/stripe/stripeActions";
import {useDispatch, useSelector} from "react-redux";
import SuccessRedirection from "../card/stripe/successRedirection";
import {Alert} from "@mui/lab";

const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  }
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  placeholderCountry: 'FR',
  style: IBAN_STYLE,
};

/*
TODO prendre en compte tous les status
https://stripe.com/docs/testing?testing-method=payment-methods#sepa-direct-debit:~:text=ACH%20Direct%20Debit-,SEPA,-debit
 */
const IbanInformation = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.stripeState.error);

  const handleIbanInformationChanged = (e) => {
    dispatch(ibanInformationFulfilled(!e.empty));
  }

  const translatedToFrench = (stripeError) => {
    switch (stripeError.code) {
      case 'invalid_iban':
        return 'L\'IBAN renseigné n\'est pas valide';
    }
  }

  return (
    <div>
      <div className="form-row">
        <label>
          <IbanElement options={IBAN_ELEMENT_OPTIONS} onChange={handleIbanInformationChanged}/>
        </label>
      </div>

      {error && (
        <div className='card-error' role='alert'>
          <Alert severity='error'>{translatedToFrench(error)}</Alert>
        </div>
      )}

      {/* Display mandate acceptance text. */}
      <div className="col" id="mandate-acceptance">
        En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez AlphaBody (Never Give
        Up) et Stripe, notre prestataire de services de paiement et/ou PPRO, son prestataire de services local, à
        envoyer des instructions à votre banque pour débiter votre compte et votre banque à débiter votre compte
        conformément à ces instructions. Vous avez, entre autres, le droit de vous faire rembourser par votre banque
        selon les modalités et conditions du contrat conclu avec votre banque.
        La demande de remboursement doit être soumise dans un délai de 8 semaines à compter de la date à laquelle votre
        compte a été débité.
        Vos droits sont expliqués dans une déclaration disponible auprès de votre banque.
        Vous acceptez de recevoir des notifications des débits à venir dans les 2 jours précédant leur réalisation.
      </div>
      <SuccessRedirection/>
    </div>
  )
}

export default IbanInformation;