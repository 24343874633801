import React from "react";

export const RecapCommand = ({product, productBump, bumpCheck, total}) => {
  return (
    <div className="recap-command">
      <div className="title-command">
        <div>Ta commande</div>
        <div>Montant</div>
      </div>
      <div className="product-command">
        <div>{product.name}</div>
        <div className="product-price">
          {product.price} Eur
          {product.crossedOutPrice && (
            <>
              &nbsp;
              <span className="crossedOutPrice">
                {`${product.crossedOutPrice} Eur`}
              </span>
              &nbsp;
              <span className="percentPrice">
                {`(${Math.round(
                  (product.price / product.crossedOutPrice) * 100
                )}%)`}
              </span>
            </>
          )}
        </div>
      </div>
      {productBump && bumpCheck && (
        <div className="product-command">
          <>
            <div>{productBump.name}</div>
            <div>{`${productBump.price} Eur`}</div>
          </>
        </div>
      )}
      <div className="total-command">
        <div>TOTAL</div>
        <div></div>
        <div>{total.finalAmount} Eur</div>
      </div>
    </div>
  )
}