import {ofType} from "redux-observable";
import {
  customerUnknown,
  fulfillCustomerInformation,
  GET_CUSTOMER_INFORMATION,
  UPSERT_CUSTOMER,
  upsertCustomerSucceeded
} from "./customerActions";
import axios from "axios";
import {forkJoin, from, map, mergeMap, switchMap, withLatestFrom} from "rxjs";
import {urlBdd} from "../configuration";
import {format} from "date-fns";
import {saveTheSale} from "../sales/salesActions";
import {allOffers} from "../offers/products/productsWithSeller";
import {SAVE_BEFORE_ALMA_REDIRECTION} from "../payment/alma/almaEpics";

export const getCustomerInformation$ = (actions$, state$) => actions$.pipe(
  ofType(GET_CUSTOMER_INFORMATION),
  switchMap((action) => forkJoin({
      basicInfo: from(fetchCustomerInformationByEmail(action.payload)).pipe(
        map((response) => response.data.data.getCustomerByMail)
      ),
      address: from(fetchCustomerAddressByEmail(action.payload)).pipe(
        map((response) => response.data[0])
      )
    })
  ),
  map((response) => !response.basicInfo ? customerUnknown('Aucun utilisateur trouvé avec cet identifiant') :
    fulfillCustomerInformation(mapToCustomer(response)))
);

export const upsertCustomer$ = (actions$, state$) => actions$.pipe(
  ofType(UPSERT_CUSTOMER),
  withLatestFrom(state$),
  switchMap(([action, state]) => from(fetchCustomerInformationByEmail(state.customerState.email))),
  map((response) => response.data.data.getCustomerByMail),
  withLatestFrom(state$),
  switchMap(([customer, state]) => !!customer ?
    from(updateCustomer({id: customer.id, ...state.customerState}, state.offerState.product)) :
    from(insertCustomer(state.customerState, state.offerState.product))),
  mergeMap((result) => [
    upsertCustomerSucceeded(findCustomerId(result.data.data)),
    saveTheSale(findCustomerId(result.data.data))
  ])
)

const findCustomerId = (data) => data.insertCustomer ? data.insertCustomer.id : data.updateCustomer?.id

const mapToCustomer = (data) => {
  const basicInfo = data.basicInfo;
  const address = data.address;

  return {
    gender: basicInfo?.product_id.gender !== 'mixte' ? basicInfo?.product_id.gender : '',
    lastname: basicInfo?.lastname,
    firstname: basicInfo?.firstname,
    email: basicInfo?.email,
    phone: basicInfo?.tel,
    address: address?.street_line_1,
    zip: address?.postal_code,
    city: address?.city,
    country: address?.country,
    basicInfo,
    basicAddress: address
  }
}

const fetchCustomerInformationByEmail = (email) => {
  return axios({
    method: 'post',
    url: `${urlBdd}/graphQl`,
    data: {
      query: `query getCustomerByMail($email: String!){
                getCustomerByMail(email: $email){
                  id
                  firstname
                  lastname
                  email,
                  tel,
                  product_id{
                    id,
                    gender
                  }
                  sale{
                    id
                      type
                      processor_cus_id
                  }
                  seller_id{
                      id
                      firstname
                      lastname
                  }
                  coach_id{
                      id
                      firstname
                      lastname
                      telegram_channel_id
                  }
                }
          }
          `,
      variables: {
        email: email,
      },
    },
  });
}

const fetchCustomerAddressByEmail = (email) => {
  return axios.get(`https://api-facturation.alphabody.fr:2053/quaderno/getAllContacts?email=${email}`);
}

const insertCustomer = (customer, product) => {
  const backup = localStorage.getItem(SAVE_BEFORE_ALMA_REDIRECTION);
  const {customerState, offerState} = backup ? JSON.parse(backup) : {};
  if(offerState) {
    product = offerState.product;
  }
  if(customerState) {
    customer = {...customerState, id: customer.id};
  }

  const productId = allOffers[product] ? allOffers[product].id : product.id;
  return axios({
    method: 'post',
    url: `${urlBdd}/graphQl`,
    data: {
      query: `
        mutation insertCustomer($firstname: String!, $lastname: String!, $email: String!, $tel: String, $password: String, $activ: Boolean, $annulation: Boolean, $created_at: String, $updated_at: String, $coach_id: Int, $seller_id: Int, $product_id: Int!){
            insertCustomer(firstname: $firstname, lastname: $lastname, email: $email, tel: $tel, password: $password, activ: $activ, annulation:$annulation, created_at: $created_at, updated_at: $updated_at, coach_id: $coach_id, seller_id: $seller_id, product_id: $product_id ){
                id
            }
        }`,
      variables: {
        firstname: customer.firstname,
        lastname: customer.lastname,
        email: customer.email,
        tel: customer.phone,
        password: 'password',
        activ: true,
        annulation: false,
        created_at: new Date(),
        updated_at: new Date(),
        seller_id: customer.sellerId !== 0 ? parseInt(customer.sellerId, 10) : null, // TODO WARNING SUR LE NULL !
        product_id: parseInt(productId, 10),
      },
    },
  })
}

const updateCustomer = (customer, product) => {
  const backup = localStorage.getItem(SAVE_BEFORE_ALMA_REDIRECTION); // TODO faire un service pour le code qui manipule le localstorage
  const {customerState, offerState} = backup ? JSON.parse(backup) : {};
  if(offerState) {
    product = offerState.product;
  }
  if(customerState) {
    customer = {...customerState, id: customer.id};
  }

  const productId = allOffers[product] ? allOffers[product].id : product.id;
  return axios({
    method: 'post',
    url: `${urlBdd}/graphQl`,
    data: {
      query: `
        mutation updateCustomer($id: Int!, $firstname: String, $lastname: String, $email: String, $tel: String, $password: String, $activ: Boolean, $annulation: Boolean, $coach_id: Int, $seller_id: Int, $product_id: Int){
            updateCustomer(id: $id, firstname: $firstname, lastname: $lastname, email: $email, tel: $tel, password: $password, activ: $activ, annulation: $annulation, coach_id: $coach_id, seller_id: $seller_id, product_id: $product_id){
                id
            }
        }`,
      variables: {
        id: parseInt(customer.id, 10),
        firstname: customer.firstname,
        lastname: customer.lastname,
        email: customer.email,
        tel: customer.phone,
        password: 'password',
        activ: true,
        annulation: false,
        seller_id: customer.sellerId !== 0 ? parseInt(customer.sellerId, 10) : null,
        product_id: parseInt(productId, 10),
      },
    },
  })
}
