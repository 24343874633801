import React from 'react'

import logoHomme from '../../images/LOGO-HOMME-TEXTE-NOIR.png'
import logoFemme from '../../images/LOGO-FEMME-TEXTE-NOIR.png'

const Logo = ({ gender }) => {
    React.useEffect(() => { })

    return (
        <div className='logo'>
            <img
                src={
                    gender === 'homme'
                        ? logoHomme
                        : logoFemme
                }
                alt='logo'
            />
        </div>
    )
}

export default Logo
