import React from "react";

function IconMan({ circleColor, topMainColor, topShadowColor, topLightColor }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="564"
            height="564"
            viewBox="0 0 564 564"
        >
            <defs>
                <path
                    id="path_1"
                    d="M0 282C0 126.256 126.256 0 282 0s282 126.256 282 282-126.256 282-282 282S0 437.744 0 282z"
                ></path>
                <path id="path_2" d="M0 0h464.056v464.056H0V0z"></path>
                <clipPath id="mask_1">
                    <use xlinkHref="#path_1"></use>
                </clipPath>
                <clipPath id="mask_2">
                    <use xlinkHref="#path_2"></use>
                </clipPath>
            </defs>
            <g>
                <path
                    fill={circleColor}
                    fillRule="evenodd"
                    stroke="none"
                    d="M0 282C0 126.256 126.256 0 282 0s282 126.256 282 282-126.256 282-282 282S0 437.744 0 282z"
                ></path>
                <g clipPath="url(#mask_1)">
                    <g
                        fillRule="evenodd"
                        stroke="none"
                        transform="translate(50.944 99.972)"
                    >
                        <path fill="none" d="M0 0h464.056v464.056H0V0z"></path>
                        <g clipPath="url(#mask_2)">
                            <path
                                fill="#FFDFBA"
                                d="M308 416.796v39.26c0 4.42-3.58 8-8 8H8c-4.42 0-8-3.58-8-8v-39.26c0-41.19 33.39-74.56 74.59-74.57 14.56-.01 27.38-7.5 34.76-18.86 7.414-11.394 6.65-21.302 6.65-29.31l.15-.37c-35.9-14.86-61.15-50.23-61.15-91.5v-3.13c-14.255 0-25-11.265-25-24.54v-41.56c-.32-14.47.34-65.5 37.2-101.03 42.86-41.31 110.78-37.93 159.98-15.83 1.6.72 1.55 3.01-.07 3.68l-12.83 5.28c-1.92.79-1.51 3.62.55 3.84l6.23.67c29.83 3.19 57.54 19.39 74.72 46.35.46.73.33 1.84-.26 2.47a60.503 60.503 0 00-16.52 41.56v54.57c0 13.55-10.99 24.54-24.54 24.54H253v3.13c0 41.27-25.25 76.64-61.15 91.5l.15.37c0 7.777-.827 17.82 6.65 29.31 7.38 11.36 20.2 18.85 34.76 18.86 41.2.01 74.59 33.38 74.59 74.57z"
                                transform="translate(78.028)"
                            ></path>
                            <path
                                fill={topMainColor ? topMainColor : "#ec6331"}
                                d="M308 93.43v39.26a8 8 0 01-8 8H8a8 8 0 01-8-8V93.43c0-41.19 33.395-74.555 74.585-74.57 14.564-.005 27.387-7.504 34.765-18.86 25.754 22.002 63.531 22.015 89.3 0 7.377 11.356 20.201 18.855 34.765 18.86C274.605 18.875 308 52.24 308 93.43z"
                                transform="translate(78.028 323.366)"
                            ></path>
                            <path
                                fill="#C78640"
                                d="M265.817 75.92c.464.73.334 1.834-.259 2.462a60.538 60.538 0 00-16.517 41.564v54.57c0 12.388-9.333 24.54-26 24.54v-61.77c0-26.51-21.49-48-48-48h-102c-26.51 0-48 21.49-48 48v61.77c-14.255 0-25-11.265-25-24.54v-41.56c-.32-14.47.34-65.5 37.2-101.03C80.098-9.386 148.025-6.005 197.217 16.099c1.6.718 1.558 3.01-.065 3.677l-12.831 5.282c-1.918.79-1.514 3.617.548 3.838l6.232.67c29.835 3.186 57.538 19.387 74.716 46.355z"
                                transform="translate(107.987)"
                            ></path>
                            <path
                                fill="#FFDAAB"
                                d="M198 112.9c0 54.696-44.348 99-99 99-51.492 0-99-40.031-99-102.13V48C0 21.49 21.49 0 48 0h102c26.51 0 48 21.49 48 48v64.9z"
                                transform="translate(133.028 89.286)"
                            ></path>
                            <path
                                fill="#FFE9CF"
                                d="M76.584 161.175c16.277 10.183 3.442 35.156-14.376 28.004C25.574 174.475 0 138.775 0 97.247v-64.9A47.79 47.79 0 018.422 5.18C14.936-4.294 30-.109 30 12.347v64.9c0 36.51 19.192 66.79 46.584 83.928z"
                                transform="translate(133.028 104.939)"
                            ></path>
                            <path
                                fill="#F2C48A"
                                d="M78.26 16.79C53.623 27.103 26.548 27.903 0 16.79 1.356 11.164 1.13 7.52 1.13.37L1.28 0c24.082 9.996 51.571 10.016 75.7 0l.15.37c0 7.153-.226 10.796 1.13 16.42z"
                                transform="translate(192.898 293.686)"
                            ></path>
                            <path
                                fill={topLightColor ? topLightColor : "#FE8C62"}
                                d="M114.882 43.017c-3.698 1.163-7.664 1.804-11.916 1.841C61.67 45.222 28 77.875 28 119.173v7.517c0 7.732-6.268 14-14 14H8a8 8 0 01-8-8V93.43c0-41.191 33.395-74.555 74.585-74.57 14.564-.005 27.387-7.504 34.765-18.86a69.21 69.21 0 009.512 6.822c14.753 8.791 12.402 31.044-3.98 36.195z"
                                transform="translate(78.028 323.366)"
                            ></path>
                            <path
                                fill={topShadowColor ? topShadowColor : "#CC5225"}
                                d="M13.786 43.017c3.697 1.163 7.664 1.804 11.915 1.841 41.297.364 74.966 33.017 74.966 74.315v7.517c0 7.732 6.268 14 14 14h6a8 8 0 008-8V93.43c0-41.191-33.395-74.555-74.584-74.57C39.519 18.855 26.696 11.356 19.317 0a69.21 69.21 0 01-9.511 6.822c-14.752 8.791-12.402 31.044 3.98 36.195z"
                                transform="translate(257.36 323.366)"
                            ></path>
                            <path
                                fill="#DB9951"
                                d="M197.151 19.776c-11.758 4.84-13.434 5.906-17.508 5.274-65.674-10.18-123.294 16.993-142.862 80.786v.01a47.761 47.761 0 00-11.74 31.44v37.523c0 16.188-25 17.315-25-.293v-41.56c-.32-14.47.34-65.5 37.2-101.03 42.86-41.31 110.78-37.93 159.98-15.83 1.6.72 1.55 3.01-.07 3.68z"
                                transform="translate(107.987)"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default IconMan;
