export const allComplements = [
  {
    name: 'Alpha Whey',
    saveur: 'Saveur Cookies & Cream',
    code: 'AlphaWhey-cookie',
    description:
      '100% Protein Isolate: Exploite ton potentiel de construction musculaire MAXIMUM',
    img: 'https://i0.wp.com/shopalpha.fr/wp-content/uploads/2021/07/SACHET-WHEY-FINAL-CHOCO_weboptim.png?resize=800%2C800&ssl=1',
  },
  {
    name: 'Alpha Whey',
    saveur: 'Saveur Glace à la Vanille',
    code: 'AlphaWhey-vanille',
    description:
      '100% Protein Isolate: Exploite ton potentiel de construction musculaire MAXIMUM',
    img: 'https://i0.wp.com/shopalpha.fr/wp-content/uploads/2021/07/SACHET-WHEY-FINAL-GLACE_weboptim.png?resize=800%2C800&ssl=1',
  },
  {
    name: 'Alpha Whey',
    saveur: 'Saveur Brownie Chocolat',
    code: 'AlphaWhey-choco',
    description:
      '100% Protein Isolate: Exploite ton potentiel de construction musculaire MAXIMUM',
    img: 'https://i0.wp.com/shopalpha.fr/wp-content/uploads/2021/07/SACHET-WHEY-FINAL-COOKIE_weboptim.png?resize=800%2C800&ssl=1',
  },
  {
    name: 'Alpha Plant - Whey Végan',
    saveur: 'Caramel au beurre salé',
    code: 'AlphaPlant-caramel',
    description:
      "La 1ere Protéine VEGAN aussi délicieuse qu'efficace: Exploite ton potentiel de construction musculaire MAXIMUM",
    img: 'https://i0.wp.com/shopalpha.fr/wp-content/uploads/2021/08/SACHET-WHEY-FINAL-VEGAN-CARAMEL.png?resize=800%2C800&ssl=1',
  },
  {
    name: 'Alpha D',
    saveur: '',
    code: 'Alpha-D',
    description:
      'Vitamine D3: Une vitamine essentielle au bon fonctionnement du corps',
    img: 'https://i0.wp.com/shopalpha.fr/wp-content/uploads/2021/06/ALPHA-D_weboptim.png?resize=800%2C800&ssl=1',
  },
  {
    name: 'AlphaOméga',
    saveur: '',
    code: 'Alpha-Omega',
    description:
      'Oméga 3: Un complémént indispenssable pour un physique au top et une bonne santé',
    img: 'https://i0.wp.com/shopalpha.fr/wp-content/uploads/2021/06/ALPHAOMEGA-1.png?resize=800%2C800&ssl=1',
  },
  /* {
           name: "Alpha Créa",
           saveur: "",
           code: "Alpha-Crea",
           description: "Créatine: Le complement fitness le plus efficace et le plus utilisé pour la musculation",
           img: "https://i0.wp.com/shopalpha.fr/wp-content/uploads/2021/12/ALPHACREA_weboptim.png?resize=800%2C800&ssl=1"
       },*/
  {
    name: 'Alpha Mag',
    saveur: '',
    code: 'Alpha-Mag',
    description:
      'Magnésium: La forme de magnésium la plus assimilable et la plus concentrée',
    img: 'https://i0.wp.com/shopalpha.fr/wp-content/uploads/2021/06/ALPHAMAG_weboptim.png?resize=800%2C800&ssl=1',
  },
  {
    name: 'Alpha Burn',
    saveur: '',
    code: 'Alpha-Burn',
    description: 'Alpha Burn: Accélère ta sèche',
    img: 'https://i0.wp.com/shopalpha.fr/wp-content/uploads/2021/06/ALPHABURN_weboptim.png?resize=800%2C800&ssl=1',
  },
];